import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Form } from "react-router-dom";

import CreatorImage from "../components/creator-image";
import { use } from "i18next";
import { useEffect, useState } from "react";
import { listPartners } from "../creator-slice";
import moment from "moment";
import { Trans } from "react-i18next";
import { getPlaformImage } from "../../../platforms/config";
import DateRangePicker from "rsuite/esm/DateRangePicker";
import WeerliveRangePicker from "../../../theme/components/date-range-picker";

const { combine, afterToday } = DateRangePicker;

const partnersImage = getPlaformImage("partners.png");

export function Partners(){
    const user = useAppSelector((state) => state.login.user);
    const partners = useAppSelector((state) => state.creator.partners);
    const dispatch = useAppDispatch();
    const [shareURL, setShareURL] = useState<string>('');
    useEffect(() => {
        dispatch(listPartners({startDate: undefined, endDate: undefined}));
    }, []);
    useEffect(() => {
        setShareURL(`${location.protocol + '//' + location.host}/p/${user?.shortId}`);
    }, [user]);
    return <>
            <Row style={{marginTop: 30}}>
                <Col xs={12} md={6}>
                    <img src={partnersImage} style={{objectFit: "contain", maxWidth: "100%"}} />
                </Col>
                <Col>
                    <h1 style={{color: "white", textTransform: "uppercase"}}><Trans i18nKey="backoffice.creator.partner.title">Refer to earn more</Trans></h1>
                    <p><Trans i18nKey="backoffice.creator.partner.description">Invite new creators to the platform and benefit from 5% of their income for 24 months.</Trans></p>
                    <Form>
                        <FormGroup>
                            <Label for="share" style={{marginTop: 50}}><Trans i18nKey="backoffice.creator.partner.share">Partager</Trans></Label>   
                            <div style={{display: "flex", alignItems:'center', 
                                backgroundColor: "#272727",
                                color: "white",
                                border: "1px solid #424242",
                                borderRadius: "10px",
                                padding: "10px"
                            }}>
                                <input style={{border: "none", flex:1, background: "transparent", color: "white"}} id="share" readOnly={true} value={shareURL}/>
                                <span style={{textDecoration: "underline", cursor:"pointer", color: "#8A8A8A"}}onClick={() => {navigator.clipboard.writeText(shareURL)}}><Trans i18nKey={"backoffice.creator.partner.copy"}>Copier</Trans></span>

                            </div>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            <Row className="align-content-center">
            <Col md={6}>
                <h1 style={{fontSize: 28, marginTop: 30}}><Trans i18nKey="backoffice.creator.partner.stats">Statistiques</Trans></h1>
            </Col>
            <WeerliveRangePicker onChange={ (range) => {
                    dispatch(listPartners({startDate: range?.[0], endDate: range?.[1]}));
            }} />

                   
        </Row>
            {partners.map((partner) => {
                return <Row className="secondary align-content-center" style={{borderRadius: 20,  padding: 30, marginTop: 10}}>
                <Col md={{size: 2}}>
                    <CreatorImage userName={partner.userName} image="" color={partner.color}/>
                </Col>
                <Col md={{size: 6}}>
                    <h1 style={{fontSize: 15}}>{partner.userName}</h1>
                    <p style={{color: "#8A8A8A"}}><Trans i18nKey={"backoffice.creator.partner.title"}>Membre depuis le</Trans> {moment(partner.createdAt).format('DD/MM/YYYY')}</p>
                </Col>
                <Col md={{size: 2}} style={{textAlign: 'center'}}>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.partner.live_count"}>Nombre de live</Trans></h1>
                    <p>{ partner.liveCount }</p>
                </Col>
                <Col md={{size: 2}} style={{textAlign: 'center'}}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.partner.revenue"}>Gain généré</Trans></h1>
                    <p>{ partner.totalRevenue.toFixed(2) }</p>
                </Col>                                
            </Row>
            })}

        </>    
}