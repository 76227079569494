
import { Alert as ReactstrapAlert } from 'reactstrap';
import useAlert from '../hooks/use-alert';
import { useEffect } from 'react';

const Alert = () => {
  const { text, type } = useAlert();
  useEffect( () => {
    if (text && type){
      window.scrollTo(0, 0);
    }
  }, [text, type]);
  if (text && type) {
    return (
      <ReactstrapAlert
        color={type}
      >
        {text}
      </ReactstrapAlert>
    );
  } else {
    return <></>;
  }
};

export default Alert;