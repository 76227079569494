import logo from '../../../logo.svg';
import keywords from '../../../assets/weerlive-keywords.svg';
import welcomeBadge from '../../../assets/welcome-badge.svg';
import { useAppSelector } from '../../../hooks';
import EventImage from './event-image';
import { EventPaymentType, EventState } from '../dto/event.dto';
import "./checkout-left-pane.css";
import useGeoLocation from "react-ipgeolocation";

export default function CheckoutLeftPane() {

    const event = useAppSelector((state) => state.events.event);
    const location = useGeoLocation();
    
    return <div className='checkout-left-pane-container'>
        { event && <><img src={logo} className='logo'/>
        <h1 className='price'>{ event.state == EventState.Vod ? event.priceVOD + " $ " : event.priceWithVAT + "$" + (event.paymentType == EventPaymentType.Time ? " / minute" : "") }</h1>
        {event && 
            <EventImage title={event.title} image={event.picture} color={event.color} />
        }
        <h2 className='title'>{ event?.title }</h2>
        </>}
    </div>
}