import { useTranslation } from "react-i18next";
import { Page } from "../../../theme/templates/page";
import { getPlatformContent } from "../../../platforms/config";
import React, {Suspense} from "react";


export default function Terms() {
    const { t } = useTranslation(); 
    const Terms = React.lazy( () => getPlatformContent('terms'))

    return (
        <Page>
            
            <Suspense>
                <Terms />
            </Suspense>
            
        </Page>
    )

}