import CreatorImage from "../../creator/components/creator-image";
import { EventDTO } from "../../event/dto/event.dto";

export interface BottomBarProps {
    event: EventDTO;
    className?: string;
}

export default function BottomBar(props: BottomBarProps){
    return <div className={`studio-bottom-bar ${props.className}`}>
        <div style={{marginRight: 10, display: "flex", alignItems:"flex-start"}}>
            <CreatorImage userName={props.event.owner.userName} style={{maxHeight: 50}} image={props.event.owner.picture} color={props.event.owner.color}/>
        </div>
        <div>
            <h2 style={{color: "#8A8A8A", fontSize: 14}}>{props.event.owner.userName}</h2>
            <h1 style={{color: "#DFDFDF", fontSize: 16}}>{props.event.title}</h1>
            {/* <p style={{color: "#8A8A8A", fontSize: 16}}>{props.event.description}</p> */}
        </div>
    </div> 
}
