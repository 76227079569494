export default function(){
    return <>
        
        
<div style={{width:'80%', marginLeft:'10%'}}>


   

    <h1 style={{marginTop:'100px'}}>GENERAL TERMS AND CONDITIONS OF USE AND GENERAL TERMS AND CONDITIONS OF SALE</h1>

    <p>Last updated on 09/10/2024</p>

    <p>These General Terms and Conditions apply between:</p>
    <ul>
        <li>HUMFFREY, a simplified joint stock company with capital of 1,000 euros, registered with the Nanterre Trade and Companies Registry under number 820 511 913, having its registered office at 14 res Beausoleil 92210 Saint Cloud, France, represented by its legal representative; Hereinafter referred to as "Blush";</li>
        <li>And the User of Blush services; Hereinafter referred to as "the User";</li>
        <li>Hereinafter referred to together as "the Parties";</li>
    </ul>

    <h3>PREAMBLE</h3>
    <p>Welcome to Blush, a true live streaming platform.</p>
    <p>The content published on Blush is the property of its Creators.</p>

    <h3>GENERAL USER DECLARATIONS</h3>
    <p>The User is a natural person acting on his/her own behalf (if he/she is a Spectator) or on behalf of a legal entity (if he/she is a Creator).</p>
    <p>In any event, the User declares that he/she has full capacity to enter into and execute the present contract, either, in the case of a natural person acting on his/her own behalf, because he/she is of full age, capable and not under any legal protection (curatorship, guardianship), or, in the case of a natural person acting on behalf of a legal entity, because he/she has the legal capacity to commit or act on behalf of the said duly registered company.</p>
    <p>By accessing Blush and using the Blush Services, the User consents to these Terms and Conditions in their entirety.</p>

    <h2>PART I // PROVISIONS COMMON TO THE GENERAL TERMS AND CONDITIONS OF USE AND SALE</h2>

    <h3>Article 1: DEFINITIONS</h3>
    <p>In these General Terms and Conditions, each of the terms hereinafter defined (capitalized and in the singular as well as in the plural) shall have the meaning set forth in the following definition:</p>

    <ul>
        <li><strong>Platform or Website:</strong> Refers to the online Platform, accessible at the following URL address: <a href="https://www.blush.live">https://www.blush.live</a> and allowing access to Blush's Services.</li>
        <li><strong>User:</strong> The person who uses the Blush Services, by means of a login and a dedicated password. Users include Spectators and Creators.</li>
        <li><strong>Spectator:</strong> The User who uses the Blush Service to attend private or public Lives.</li>
        <li><strong>Creator:</strong> The Creator is the User who uses Blush's Services to organize and host private or public paid Lives for Spectators. The Creator is a legal entity.</li>
        <li><strong>Live:</strong> Refers to conferences, coaching sessions, speeches, etc. and more generally to all content broadcast live on the Platform and created by Creators, of which they remain the property.</li>
        <li><strong>Profile:</strong> This is the User's personal account, identified by a unique login and protected by a secure, personal password, the confidentiality of which the User is responsible for. By connecting to his/her Profile, the User will have access to Blush's services.</li>
        <li><strong>News feed:</strong> Refers to the Platform's home page, which highlights certain content and provides information on upcoming Lives.</li>
        <li><strong>Force Majeure:</strong> Force majeure is a legally defined concept. Force majeure occurs when an unforeseeable, irresistible event, external to the parties to the contract and of such a nature as to prevent the normal performance of the contract, either temporarily or permanently, occurs.</li>
        <li><strong>Corrective Maintenance Operation:</strong> Maintenance operation aimed at correcting a major malfunction of the Platform and which must be carried out relatively quickly in order to allow normal use of Blush's Services.</li>
        <li><strong>Evolutionary Maintenance Operation:</strong> Maintenance operations to improve Blush services, which can be scheduled in advance.</li>
        <li><strong>Sponsor:</strong> Creator who joins the Platform by means of a referral link. This must be a new registration.</li>
    </ul>

    <h3>Article 2: PURPOSE</h3>
    <p>The purpose of these Terms and Conditions is to define the terms and conditions applicable to the services developed and provided by Blush.</p>

    <h3>Article 3: THE CONTRACT</h3>
    <p><strong>3.1. The Contractual Base:</strong> The present General Terms and Conditions are binding on the parties.</p>
    <p>In this case, and in the event of contradiction between one or more stipulations of the contractual documents making up the Contractual Base, the stipulations of the General Conditions shall prevail.</p>
    <p><strong>3.2. Acceptance of the General Terms and Conditions:</strong> The User is deemed to have carefully read the present General Terms and Conditions and to have understood their meaning and the scope of his commitment thereto. He/she is invited to download them and keep a copy.</p>
    <p>The User who accesses Blush's services is deemed to accept the present Terms and Conditions in their entirety and agrees to comply with them in all respects.</p>
    <p>If this is not the case, the User must not use or access the Platform.</p>
    <p><strong>3.3 Enforceability of updates to these General Terms and Conditions:</strong> These Terms and Conditions are subject to change in order to take into account the evolution of the Services offered by Blush and, if applicable, legislation.</p>
    <p>The Parties expressly agree that all future updates will be enforceable against the User by operation of law, provided that the User has been notified of them by any means at least 7 calendar days before they take effect.</p>
    <p><strong>3.4. Revocation of consent:</strong> The User has the right to revoke his consent at any time, by ceasing to use Blush's Services and closing his Profile.</p>
    <p>However, the revocation of the User's consent is valid only for the future and does not affect the validity of the consent previously given or the applicability of the provisions of this Contract that were accepted prior to said revocation.</p>

    <h3>Article 4: CONSUMER USER PROTECTION</h3>
    <p><strong>4.1. Prior mediation:</strong> Blush reminds Users who are consumers that they have the possibility, in the event of a dispute arising from the use of the Platform, to have recourse to prior mediation before taking any legal action.</p>
    <p>The Party wishing to initiate mediation must inform the other Party by registered letter with acknowledgement of receipt, stating the grounds for the dispute, and contact the Association Nationale des Médiateurs (62 rue Tiquetonne -75002 Paris or www.anm-conso.com).</p>
    <p>It is reminded that mediation is not compulsory and that the User or Blush may withdraw from the process at any time, without this restricting either Party's right to take legal action.</p>
    <p>As mediation is confidential, all exchanges during the process are subject to secrecy and cannot be revealed in any subsequent legal proceedings.</p>
    <p><strong>4.2 Legal warranty of conformity:</strong> In accordance with the provisions of article L. 224-25-12 of the French Consumer Code, Blush guarantees that the Services provided will comply with the present terms and conditions and will be free of defects in conformity, as defined in article L. 225-25-10 of the French Consumer Code, at the time of their provision.</p>
    <p>This guarantee of conformity applies for the duration of the contract.</p>
    <p>In the event of a duly noted lack of conformity, Blush undertakes to remedy the defect as soon as possible and at its own expense within a maximum period of 30 days.</p>
    <p>In the event of a proven lack of conformity, the User may choose between bringing the Service into conformity, reducing the price or cancelling the contract. If the User opts for a price reduction, he/she is informed that the reduced price will be proportional to the difference between the value of the non-compliant Service and that of the defect-free Service.</p>
    <p>This warranty does not apply to defects resulting from improper or abusive use of Blush's Services, unauthorized modifications to the Services, Force Majeure or any other event beyond the control of Blush.</p>
    <p>Similarly, the present warranty shall not apply in the event of incompatibility between the Service and the User's digital environment, or in the event of damage to Internet services.</p>

    <h3>Article 5: MISCELLANEOUS PROVISIONS</h3>
    <p>These Terms and Conditions are governed by French law.</p>
    <p>Subject to the application of the provisions of public order, any dispute arising from the use of the Site or as a result of the present Terms and Conditions shall fall within the exclusive jurisdiction of the French courts.</p>
    <p>Blush's failure or delay in exercising any of its rights hereunder or under law shall not constitute or be construed as a waiver of such rights.</p>
    <p>Should any of the articles herein be considered null and void by any competent court, it shall be deemed unwritten but shall not invalidate or render null and void the other articles of these Terms of Use and Sale.</p>
    <p>The User acknowledges and agrees that Blush may, at any time, assign and transfer to a third party the rights and responsibilities hereunder; such third party may then assume the responsibilities and obligations hereunder, of which the User shall be notified in a timely manner.</p>
    <p>The titles of the articles in these General Terms and Conditions are for information purposes only.</p>

    <h2>PART II // TERMS AND CONDITIONS OF USE</h2>

    <h3>Article 6: DESCRIPTION OF SERVICES</h3>

    <h4>6.1. Blush Platform services</h4>
    <p>Blush provides an online platform operator service, much like a web host.</p>
    <p>The user uses Blush either to broadcast live content (Creator), or to attend these broadcasts (Spectator), which can be public or private.</p>
    <p>Thus, the content broadcast (Live) is the sole responsibility of the Creator and remains his or her property. It is also up to the Creator to set the price of his Live.</p>
    <p>Access to the Platform is free, subject to prior registration.</p>
    <p>The Platform enables:</p>
    <ul>
        <li><strong>To the Creators:</strong> Broadcast paid Lives, publicly or privately. To do this, Blush enables the creator to generate a live session with the creation of a sharing link that allows Spectators to join the session;</li>
        <li>To collect the fees related to their services, in the form of a retrocession from Blush, after collection of Blush's fees;</li>
        <li>To offer a replay of the Lives it has chosen to record (Replay).</li>
    </ul>

    <ul>
        <li><strong>To Users:</strong> Attend Lives or Replays; Consult the Platform News Feed; Chat during Lives.</li>
    </ul>
    <p>Access to Lives and Replays is subject to a fee, the amount of which is determined by the Creator.</p>
    <p>Pricing for Lives is either:</p>
    <ul>
        <li>An "entry ticket" pricing system, which involves paying to take part in Live;</li>
        <li>A "by the minute" pricing system, based on the amount of time the spectator spends in front of the Creator's Live show.</li>
    </ul>
    <p>Replays are always priced on a flat-rate basis.</p>

    <h4>6.2. Service availability</h4>
    <p>In order to continuously improve the services offered, Blush reserves the right to carry out corrective and evolutionary maintenance operations on the Platform.</p>
    <p>The User acknowledges that these maintenance operations may render access to Blush's services temporarily unavailable.</p>
    <p>Users will be informed 48 hours in advance of a Corrective Maintenance Operation, and 8 days in advance of an Evolutive Maintenance Operation.</p>
    <p>It is specified that Blush will make its best efforts to schedule these maintenance operations on slots without scheduled Live. Failing this, the Creator will be deemed to have had the necessary time to reschedule its Live.</p>

    <h4>6.3. Customer support</h4>
    <p>A helpdesk is available at hello@blush.live.</p>
    <p>Customer support is not provided by telephone, but only by message at first.</p>
    <p>Blush undertakes to provide a response to the User within 48 hours. Depending on the nature of the intervention, Customer Support may inform the User of an additional deadline for resolving the problem.</p>
    <p>Any abusive use of Customer Support may be subject to sanctions by Blush, up to and including cancellation of the User's membership.</p>

    <h4>6.4. Sponsorship</h4>
    <p>Each Creator will be allocated a sponsorship link enabling him/her to invite other Creators to join the Platform, who will become his/her Recommendees. The financial terms of sponsorship are detailed below, in the section devoted to the General Terms and Conditions of Sale.</p>
    <p>This referral link will be active for 36 months from the registration of the Creator to whom it is assigned.</p>
    <p>The Creator is informed and agrees that this referral program may be suspended or terminated at Blush's sole discretion.</p>

    <h3>Article 7: USE OF SERVICES</h3>

    <h4>7.1. Application process</h4>
    <p>The usage process is, in part, identical for all Users, whether Creators or Spectators.</p>

    <h5>i. Prerequisites</h5>
    <p>It is the User's responsibility, at his/her own expense and under his/her sole responsibility, to acquire the technical means necessary to use Blush's services, in particular a computer and/or cell phone and Internet access. If this entails costs for the User, the User may not claim reimbursement from Blush.</p>
    <p>The User is warned of the technical hazards inherent in the Internet and of access interruptions that may result, for which Blush cannot be held responsible.</p>
    <p>In doing so, Blush cannot guarantee the availability and complete and permanent functionality of its services since their use involves remote execution via the Internet.</p>

    <h5>ii. Register and create your personal space</h5>
    <p>In order to use Blush's Services, each User must create a Profile and, in doing so, provide a certain amount of personal information.</p>
    <p>Spectators are required to enter their email address, create a password and indicate their country of residence.</p>
    <p>Creators must also fill in:</p>
    <ul>
        <li>Name;</li>
        <li>First name;</li>
        <li>Sector of activity;</li>
        <li>Profile picture;</li>
        <li>Link to other social networks (Instagram, TikTok, Youtube and X);</li>
        <li>Phone number;</li>
        <li>Process the ID verification</li>
        <li>The IBAN of the bank account from which Lives funds will be drawn.</li>
        <li>Their compagny infos</li>
    </ul>
    <p>This information must be provided truthfully, completely and accurately and must be kept up to date on a regular basis. Any change must be notified to Blush as soon as possible by e-mail to the following address: hello@blush.live.</p>
    <p>In order to access Blush's Services, the User must first create a Profile by providing an accurate e-mail address that may be used by Blush to send the User legal notifications or notifications related to the Services, and define a personal and confidential password of a sufficiently secure level and comprising at least 8 characters of the User's choice.</p>
    <p>The user must also choose a login or user name, the choice of which is in principle free but which Blush reserves the right to restrict in the event of counterfeiting, usurpation or contradiction with public order and morality.</p>
    <p>The User is responsible for maintaining the confidentiality and correct use of his e-mail address, login and password. He/she alone is responsible for their safekeeping and the risks of loss, and for preventing their disclosure to third parties by any means.</p>
    <p>In so doing, the User is solely responsible for the use by any other person of his or her connection data, and will remain liable for all operations carried out from his or her personal space.</p>
    <p>In the event of loss, forgetfulness or unauthorized use of login data, the User must immediately inform Blush by e-mail at the following address: hello@blush.live and, where applicable, follow the security control rules necessary to restore the User's access to his personal space.</p>
    <p>To finalize the creation of his account, the User must have read and accepted the present Terms of Use.</p>
    <p>A User may only create a single account. Blush declines all responsibility in the event of non-compliance with this instruction and shall not be held liable for the harmful consequences of holding multiple accounts.</p>

    <h5>iii. KYC procedures</h5>
    <p>To finalize the creation of their profile, Creators must provide additional information and agree to submit to KYC (Know Your Customer) procedures so that Blush can verify their identity and legitimacy to use the Platform.</p>
    <p>By subscribing to the present contract, the Creator agrees to provide accurate, complete and up-to-date information, including in particular his/her surname, first name and a copy of a valid form of identification (passport, driver's license or national identity card).</p>
    <p>Blush may ask the user for additional documents at a later date. The user must send the requested documents without delay, unless Blush deletes the User's Profile.</p>
    <p>Information collected as part of KYC procedures will be treated in accordance with Blush's privacy policy and will be used solely for the purpose of verifying the User's identity and complying with applicable regulations on the fight against money laundering, terrorist financing and other illegal activities.</p>
    <p>Access to certain Blush Services may be subject to the completion of KYC procedures. Blush therefore reserves the right to deny access to these Services to any person who does not meet identity verification requirements.</p>

    <h5>iv. Account access and management</h5>
    <p>Once a Profile has been created, the User may personalize it by modifying his or her pseudonym and password and by adding a profile photo, which must not offend public decency, infringe the rights of others or be of an illegal or illicit nature.</p>
    <p>The User is entirely responsible for the accuracy and updating of the data communicated when opening and managing his/her Profile.</p>

    <h5>v. Deleting the Spectator Profile</h5>
    <p>At any time, the Spectator may send a request to Blush to have their Profile deleted. This request must be made by e-mail, to the following address: hello@blush.live or by using the button provided for this purpose on their Profile.</p>
    <p>For the request to succeed, the Spectator must send an e-mail from the address associated with his/her Profile and indicate "DELETION REQUEST" in the subject line.</p>
    <p>Subject to compliance with this procedure, Blush undertakes to delete the account and associated information within a maximum of 7 days. The Spectator will receive an e-mail confirming the effective deletion of his/her profile.</p>
    <p>All cancellations are final and will not be reimbursed.</p>
    <p>Specifically, if the Spectator decides to delete his/her account even though he/she has already paid for an upcoming Live, the Spectator may not request any reimbursement or compensation from Blush or the Creator.</p>

    <h5>vi. Deleting Creators' Profiles</h5>
    <p>Deletion of the Creator Profile may be requested at any time.</p>
    <p>This request must be made by e-mail, to the following address: hello@blush.live or by using the dedicated button.</p>
    <p>For the request to succeed, the Creator must send an e-mail from the address associated with his/her Profile and indicate "DELETION REQUEST" in the subject line.</p>
    <p>However, deletion of the Profile will only be effective from the moment when no Live is scheduled, unless no participant has yet registered. Failing this, the Creator must honor his or her commitment before his or her account is definitively deleted.</p>
    <p>Subject to compliance with this procedure, Blush undertakes to delete the account and associated information within 7 days.</p>
    <p>If any fees remain due to the Creator for past Lives or Replays, they will be paid within the usual deadlines, without affecting the deletion of his or her Profile, which is only valid for the future.</p>
    <p>All deletions are final.</p>

    <h4>7.2. Access to Lives</h4>
    <p>Lives are broadcast by the Creator via the Platform.</p>
    <p>It announces this on its dedicated page, thus displaying the Live broadcast terms (schedule, date, cost, duration, private, public, etc.) decided by the Creator, under its control and sole responsibility.</p>
    <p>The Spectator who wishes to participate in the Live, registers by clicking on the dedicated button and pays the relevant fee. Registration is only valid once payment has been made and a confirmation e-mail has been received.</p>
    <p>Subsequently, Spectators who regularly register for the Live will receive a connection link sent by the Creator, in order to join the Live when the time comes.</p>
    <p>The pricing conditions for Lives are set out below in the General Terms and Conditions of Sale.</p>

    <h4>7.3. Using chat</h4>
    <p>During Live, an instant messaging system (Chat) may be made available to Spectators, at the Creator's discretion.</p>
    <p>This Chat allows only the exchange of written messages, to the exclusion of all other content.</p>
    <p>The Creator may activate and deactivate the Chat at any time. He may also mute a Spectator's messages if his behavior during the session does not comply with this Contract.</p>
    <p>In addition to the moderation performed by Blush's dedicated team, the Creator has direct control over the moderation of their chat during live sessions. The Creator can issue warnings (alerts) to spectators who engage in inappropriate behavior or violate the platform's rules. After receiving three alerts, the spectator will be automatically banned from the live session.</p>

<p>Furthermore, the Creator has the authority to immediately ban any viewer without prior alerts if they deem the spectator's behavior to be severely disruptive or in violation of the platform's standards. This ensures that the Creator can maintain a respectful and safe environment in real-time during their live interactions.</p>
    <p>Spectators agree to use the Chat in a responsible and respectful manner towards other Platform Users. Any inappropriate behavior, such as harassment, intimidation, or the dissemination of offensive, discriminatory or illegal content, is strictly prohibited.</p>
    <p>In the event of non-compliance with the present terms and conditions of use of the Chat, Blush reserves the right to remove the Viewer's access to the Chat function for an indefinite period of time. Restoration of access to the Chat may be made by decision of Blush or, by decision of a Creator and for a determined Live and under his responsibility.</p>
    <p>Users' attention is drawn to the fact that, although Blush takes measures to ensure the confidentiality of chat exchanges, it is not possible for Blush to guarantee the absolute security of information shared via this means of communication.</p>

    <h3>Article 8: DURATION OF CONTRACT</h3>
    <p>The User commits to an indefinite term, until terminated by either party in accordance with the terms and conditions set out in article 9.</p>

    <h3>Article 9: END OF CONTRACT: TERMINATION</h3>

    <h4>9.1. Termination at the User's initiative: general case</h4>
    <p>The User may terminate this contract at any time, without penalty, by clicking on the unsubscribe button on his Profile or by sending an e-mail with the subject "UNSUBSCRIBE" from the e-mail address linked to his Profile.</p>
    <p>To do so, the User must indicate the reason for cancellation and then confirm his wish to unsubscribe by entering his personal and confidential password.</p>
    <p>They will then receive an email confirming that their unsubscription has been taken into account.</p>
    <p>The subscription will be cancelled within 3 days.</p>

    <h4>9.2 Termination at the initiative of Blush</h4>
    <p>Blush reserves the right to terminate this agreement, delete the Profile and suspend the User's access, without prior notice, in the following cases:</p>
    <ul>
        <li>The User does not respect the present contract;</li>
        <li>If requested by a competent authority;</li>
        <li>Occurrence of a Force Majeure event permanently preventing performance of the contract.</li>
    </ul>

    <h4>9.3. Effects of termination</h4>
    <p>Termination of this contract shall not give rise to any reimbursement by Blush of sums already invoiced in execution of the contract.</p>
    <p>No termination indemnity may be claimed from Blush.</p>
    <p>If Blush owes the Creator sums for past Lives, these sums will be paid within the usual time limits.</p>
    <p>The data linked to the User's Profile will be deleted within 7 days.</p>

     <h4>9.4. Cancellation</h4>
    <p>In accordance with the provisions of article L.221-18 of the French Consumer Code, the Spectator has a period of 14 days from the conclusion of this Contract to exercise his/her right of withdrawal, without having to justify his/her reasons or pay any penalties.</p>
    <p>To exercise his/her right of withdrawal, the Spectator must notify Blush of his/her decision in writing, by sending an e-mail from the e-mail address associated with his/her Profile, with the following subject line "EXERCISE MY RIGHT OF WITHDRAWAL" and attaching the duly completed form (Appendix I).</p>
    <p>In the event of a validly exercised retraction, Blush will reimburse the Spectator for payments received for future Lives, within 14 days.</p>
    <p>By way of exception, the Spectator is hereby informed that his/her right of withdrawal may no longer be exercised once the contract has been executed, i.e. once the service has been delivered, regardless of whether the 14-day period has not expired.</p>
    <p>The fact that the Spectator attends a scheduled Live event before expiry of the withdrawal period characterizes the Spectator's express wish to waive his/her right to withdraw.</p>

    <h3>APPENDIX I - Withdrawal form</h3>
    <p>CONSUMER RIGHT OF WITHDRAWAL - EXCERPTS FROM THE CONSUMER CODE</p>
    <p><strong>Article L221-18</strong></p>
    <p>Consumers have a period of fourteen days to exercise their right to withdraw from a contract concluded at a distance, by telephone or off-premises canvassing, without having to give reasons for their decision or incur costs other than those provided for in articles L. 221-23 to L. 221-25. The period mentioned in the first paragraph runs from the day: 1° Of the conclusion of the contract, for contracts for the provision of services and those mentioned in article L. 221-4; 2° Of receipt of the goods by the consumer or a third party, other than the carrier, designated by the consumer, for contracts for the sale of goods. For off-premises contracts, the consumer may exercise his right of withdrawal from the moment the contract is concluded. In the case of an order for several goods delivered separately, or in the case of an order for a good made up of multiple batches or parts whose delivery is staggered over a defined period, the period runs from receipt of the last good, batch or part. For contracts providing for the regular delivery of goods over a defined period, the period runs from receipt of the first good.</p>

    <p><strong>Article L221-19</strong></p>
    <p>In accordance with Council Regulation no. 1182/71/EEC of 3 June 1971 determining the rules applicable to periods, dates and terms: 1° The day on which the contract is concluded or the day on which the goods are received is not counted in the period mentioned in article L. 221-18; 2° The period begins at the start of the first hour of the first day and ends at the end of the last hour of the last day of the period; 3° If the period expires on a Saturday, Sunday or public holiday, it is extended until the next working day.</p>

    <p><strong>Article L221-20</strong></p>
    <p>Where information on the right of withdrawal has not been provided to the consumer under the conditions set out in 2° of article L. 221-5, the withdrawal period is extended by twelve months from the expiry of the initial withdrawal period, determined in accordance with article L. 221-18. However, where the provision of this information occurs during this extension, the withdrawal period expires at the end of a period of fourteen days from the day on which the consumer received this information.</p>

    <p><strong>Article L221-21</strong></p>
    <p>The consumer exercises his right of withdrawal by informing the trader of his decision to withdraw by sending, before the expiry of the period provided for in article L. 221-18, the withdrawal form mentioned in 2° of article L. 221-5 or any other unambiguous statement expressing his wish to withdraw. The trader may also allow the consumer to fill in and submit online, on his website, the form or declaration provided for in the first paragraph. In this case, the trader shall immediately provide the consumer with an acknowledgement of receipt of the withdrawal on a durable medium.</p>

    <p><strong>Article L221-22</strong></p>
    <p>The burden of proof that the right of withdrawal has been exercised under the conditions set out in article L. 221-21 lies with the consumer.</p>

    <p><strong>Article L221-23</strong></p>
    <p>The consumer shall return or restitute the goods to the trader or to a person designated by the latter, without undue delay and, at the latest, within fourteen days of communicating his decision to withdraw in accordance with Article L. 221-21unless the trader offers to collect the goods himself. The consumer only bears the direct costs of returning the goods, unless the professional agrees to bear them or has failed to inform the consumer that these costs are to be borne by him. However, for off-premises contracts, where the goods are delivered to the consumer's home at the time the contract is concluded, the trader will collect the goods at his own expense if they cannot be returned normally by post due to their nature. The consumer may only be held liable in the event of depreciation of the goods resulting from handling other than that necessary to establish the nature, characteristics and proper functioning of these goods, provided that the professional has informed the consumer of his right of withdrawal, in accordance with 2° of article L. 221-5.</p>

    <p><strong>Article L221-24</strong></p>
    <p>Where the right of withdrawal is exercised, the trader shall reimburse the consumer for all sums paid, including delivery costs, without undue delay and no later than fourteen days from the date on which he is informed of the consumer's decision to withdraw. For contracts for the sale of goods, unless the trader offers to collect the goods himself, he may defer reimbursement until he has collected the goods or until the consumer has provided proof of dispatch of the goods, whichever is the earlier. The trader shall make the refund using the same means of payment as the one used by the consumer for the initial transaction, unless the consumer expressly agrees to use another means of payment and insofar as the refund does not incur any costs for the consumer. The trader is not obliged to reimburse additional costs if the consumer has expressly chosen a delivery method that is more expensive than the standard delivery method offered by the trader.</p>

    <p><strong>Article L221-25</strong></p>
    <p>If the consumer wishes the performance of a service contract or a contract mentioned in the first paragraph of article L. 221-4 to begin before the end of the withdrawal period mentioned in article L. 221-18the trader shall receive his express request by any means for contracts concluded at a distance and on paper or on a durable medium for contracts concluded off-premises. Consumers who have exercised their right to withdraw from a contract for the provision of services or from a contract mentioned in the first paragraph of article L. 221-4, the performance of which has begun, at their express request, before the end of the withdrawal period, shall pay the trader an amount corresponding to the service provided up to the time of communication of their decision to withdraw; this amount is proportionate to the total price of the service agreed in the contract. If the total price is excessive, the appropriate amount is calculated on the basis of the market value of what has been supplied. No sum is payable by the consumer who has exercised his right of withdrawal if his express request has not been received in application of the first paragraph or if the professional has not complied with the obligation to provide information set out in 4° of article L. 221-5.</p>

    <p><strong>Article L221-26</strong></p>
    <p>A consumer who has exercised his right of withdrawal from a contract for the supply of digital content not provided on a tangible medium is not liable for any sum if:</p>
    <ul>
        <li>1° The trader has not obtained the consumer's prior express agreement to perform the contract before the end of the withdrawal period, and has not provided proof that the consumer has waived his or her right to withdraw;</li>
        <li>2° The contract does not include the information specified in the second paragraph of articles L. 221-9 and L. 221-13.</li>
    </ul>

    <p><strong>Article L221-27</strong></p>
    <p>The exercise of the right of withdrawal terminates the obligation of the parties either to perform the distance or off-premises contract, or to conclude it when the consumer has made an offer. Exercising the right to withdraw from a main distance or off-premises contract automatically terminates any accessory contract, at no cost to the consumer other than those provided for in articles L. 221-23 to L. 221-25.</p>

    <p><strong>Article L221-28</strong></p>
    <p>The right of withdrawal cannot be exercised for contracts:</p>
    <ul>
        <li>1° The supply of services fully executed before the end of the withdrawal period and whose execution has begun after prior express agreement by the consumer and express renunciation of his right of withdrawal;</li>
        <li>2° The supply of goods or services whose price depends on fluctuations in the financial market beyond the control of the professional and likely to occur during the withdrawal period;</li>
        <li>3° The supply of goods made to the specifications of the consumer or clearly personalized;</li>
        <li>4° The supply of goods likely to deteriorate or expire quickly;</li>
        <li>5° The supply of goods which have been unsealed by the consumer after delivery and which cannot be returned for reasons of hygiene or health protection;</li>
        <li>6° The supply of goods which, after delivery and by their nature, are indissociably mixed with other articles;</li>
        <li>7° The supply of alcoholic beverages whose delivery is deferred beyond thirty days and whose value agreed at the conclusion of the contract depends on fluctuations in the market beyond the control of the professional;</li>
        <li>8° Maintenance or repair work to be carried out urgently at the consumer's home and expressly requested by the consumer, within the limit of spare parts and work strictly necessary to meet the emergency;</li>
        <li>9° Supply of audio or video recordings or computer software when they have been unsealed by the consumer after delivery;</li>
        <li>10° Supply of a newspaper, periodical or magazine, except for subscription contracts for these publications;</li>
        <li>11° Concluded at a public auction;</li>
        <li>12° Provision of accommodation services, other than residential accommodation, transport of goods, car rental, catering or leisure activities which must be provided on a specific date or during a specific period;</li>
        <li>13° Provision of digital content not supplied on a tangible medium, the performance of which has begun after the consumer's express prior agreement and express waiver of his right of withdrawal.</li>
    </ul>

    <h3>CANCELLATION FORM</h3>
    <p>Please copy and return this form by e-mail as described in article 19 only if you wish to withdraw from the contract.</p>
    <p>To the attention of Blush,</p>
    <p>I, the undersigned..............................................................living at............................................................................................hereby notify you of my intention to withdraw from this contract, signed on.....................................................</p>

    <h3>Article 10: USER'S OBLIGATIONS</h3>

    <h4>10.1. General</h4>
    <p>The User undertakes to:</p>
    <ul>
        <li>Respect the terms and conditions presented here.;</li>
        <li>Provide truthful, complete and accurate information and keep it up to date;</li>
        <li>Guarantee the security and confidentiality of your e-mails, login and password;</li>
        <li>Not to use a login and password belonging to another User, regardless of any authorization given by the latter;</li>
        <li>Comply with any instructions that may be given by a duly authorized agent or representative of Blush;</li>
        <li>Respect the rules of politeness, courtesy and good manners when interacting with other Users, whether Creators or Spectators.</li>
        <li>Use Blush's services in accordance with their purpose. IN SO DOING, THE USER EXPRESSLY AGREES NOT TO, DIRECTLY OR INDIRECTLY:</li>
        <li>Infringe legal or regulatory provisions, behave in a manner contrary to public order or morality;</li>
        <li>Abuse, harass, threaten, incite hatred, make defamatory, obscene, racist, abusive or insulting comments, or infringe on the rights of others (including privacy and publicity);</li>
        <li>Disseminating messages of a paedophile nature; Child Pornography (anyone under the age of 18)</li>
        <li>Distribute or promote content involving individuals under 18 years of age, or content that appears to involve individuals under 18 years of age. If the term “teen” is used, it must be explicitly stated that the individuals are at least 18 or 19 years old;</li> 
        <li>Display or describe non-consensual sexual acts, including oral, anal, or vaginal penetration by any means (whether sex organs, objects, or fingers), or suggest situations of coercion such as rape, forced encounters, or scenarios where consent cannot be obtained (e.g., when the person is unconscious, drugged, asleep, intoxicated, hypnotized, etc.);</li> 
        <li>Create or share content depicting sexual activities with animals or non-human creatures, such as aliens or mythological beings. Pets and similar content are also prohibited;</li> 
        <li>Depict hate crimes, physical injury, mutilation, extreme violence, sexual violence, or pain, as well as any content involving blood (including menstrual blood) or feces;</li> 
        <li>Feature any individuals in the background of content, even if they are far away or blurred, to avoid any use of non-consensual images;</li> 
        <li>Show alcoholic beverages, or anything resembling them, as well as any illegal substances, in any form;</li> 
        <li>Include links to third-party sites that host content violating these standards. These links must be removed if the content on those external sites is beyond the merchant's control or monitoring capabilities, such as links to cam sites.</li> 
        </ul>
    

    <h4>10.2. Reporting abuse</h4>
    <p>If a User observes, on the Platform, the presence of manifestly illicit content or any behavior that violates the Platform's standards (such as hate speech, public drunkenness, or any other content contrary to these General Terms of Use), they must promptly notify Blush by sending an email to hello@blush.live.</p>
    <p>Blush handles two types of complaints:</p>
    <ul>
    <li>General complaints: These include issues like website malfunctions or standard-violating content (e.g., inappropriate behavior, hate speech). The resolution timeframe for these complaints is 7 days.</li>
    <li>Content removal requests: These are reserved for illegal content (such as content involving minors, drugs, or other illicit activities) and for cases of non-consensual use of images. Such cases are handled within a 24-hour timeframe, without the need for investigation. Once removed, such content cannot be re-uploaded to the Platform.</li>
     </ul>
    <p>Blush reminds Users that submitting an abusive notification may result in legal consequences, including up to one year of imprisonment and a fine of 15,000 euros, under article 6-I-4 of law n°2004-575.</p>

    <h5>i. Removal of Illegal Content as Defined by the LCEN</h5>
    <p>As per article 6 I 2° of the French law for confidence in the digital economy (LCEN) of June 21, 2004, Blush operates as a host. Therefore, Blush is committed to promptly removing any manifestly illicit content upon receiving proper notification.</p>
    <p>A valid notification must be made via the dedicated button or by sending an email to hello@blush.live and, in accordance with article 6 I 5° of the LCEN, it must include the following elements:</p>
    <ul>
    <li>Notification date;</li>
    <li>The identity of the notifier (if a natural person: surname, first name, profession, address, nationality, date and place of birth / if a legal entity: form, name, registered office, legal representative);</li>
    <li>A description of the disputed content and its location on the Platform;</li>
    <li>The reasons why the content must be removed and the reference to the relevant legal text;</li>
    <li>A copy of the correspondence sent to the author or publisher of the disputed content, requesting its removal or modification, or proof that the author could not be contacted.</li>
    </ul>


    <h5>ii. Combating the Dissemination of Offences</h5>
   <p>Blush is committed to reporting to public authorities any illegal activities brought to its attention that fall under the scope of articles 24 (paragraphs 5, 7, and 8) and 24 bis of the law of July 29, 1881, on the freedom of the press, as well as articles 222-33, 222-33-2-3, 225-4-1, 225-4-13, 225-5, 225-6, 227-23, 227-24, and 421-2-5 of the French Penal Code.</p>

<h5>iii. Combating Cyber-Threats and Cyber-Pimping</h5>
   <p>In order to fight against cyber-threats and cyber-pimping, Blush informs Users that their identities will be shared with law enforcement agencies upon request.</p>




 

    <h4>10.4 Obligations specific to Creators</h4>
    <p>The Creator is responsible for his or her Creations, which must at all times comply with the present terms and conditions.</p>
    <p>The Creator warrants to Blush that it has received the consent of any person appearing on its Designs. More generally, the Creator warrants to Blush that the Designs do not infringe the rights of third parties, and in particular their right to image, privacy and intellectual property rights.</p>
    <p>The Creator undertakes not to involve or include minors in his Lives.</p>
    <p>In the event that the Creator is assisted by third parties (agents, representatives, partners, etc.), the Creator shall be responsible for such third parties' compliance with these terms and conditions. In the event of infringement, non-compliance or damage caused by a third party acting on behalf of the Creator, the Creator shall remain solely liable to Blush and the Spectators, and shall be responsible for subsequently taking action against the perpetrator.</p>
    <p>Finally, the Creator undertakes to broadcast the Lives he/she schedules in advance. Any breach of this fundamental obligation, which is not justified by a legitimate reason (illness, Force Majeure), may result in the Creator's banishment from the Platform, in addition to compensation for the prejudice suffered by Blush as a result of this breach.</p>

    <h3>Article 11: Blush'S OBLIGATIONS AND LIABILITIES</h3>

    <h4>11.1. Blush's general obligations</h4>
    <p>Blush's obligation is an obligation of means, to the exclusion of any obligation of result or reinforced obligation of means.</p>
    <p>Blush commits to:</p>
    <ul>
        <li>Implement all means to ensure continuity of access and use of the Platform, 24 hours a day, 7 days a week;</li>
        <li>In the event of malfunction of the Services, to propose a workaround solution within a reasonable time.</li>
    </ul>

  

    <h4>11.2. Limits to Blush's liability</h4>
    <p>Blush shall not be held liable in the following cases:</p>
    <ul>
        <li>In the event of non-compliance with these Terms and Conditions of Use attributable to the User;</li>
        <li>In the event of malfunction or processing delay due to Force Majeure or a third party;</li>
        <li>In the event of unlawful conduct by a User;</li>
        <li>In the event of inability to access the Platform due to a general breakdown of the Internet networks;</li>
        <li>In the event of malfunction of the Creator's or Spectator's computer equipment;</li>
        <li>In the event of a virus attack or illicit intrusion into the Platform's systems;</li>
    </ul>
    <p>In the event of abnormal or illicit use of the Platform, the User shall be solely liable for any damage thus caused and for the consequences and actions for liability and compensation that may arise therefrom.</p>

    <h3>Article 12: USERS' LIABILITIES</h3>

    <h4>12.1. Responsibilities common to all Users and consequences</h4>
    <p>The User is solely responsible for its use of Blush Services.</p>
    <p>In the event of a breach, Blush may, at its discretion, suspend or delete the User's Profile, depending on the seriousness of the breach or the situation of a possible recurrence.</p>
    <p>Suspension and deletion of the User's Profile have the same consequence, namely to make Blush's Services unavailable for a fixed (suspension) or indefinite (deletion) period of time.</p>
    <p>The User whose account has been suspended or deleted, whether he is a Creator with a scheduled Live or a Spectator who has already paid for a Live in which he intended to participate, will not be entitled to any compensation or refund.</p>

    <h4>12.2. Disputes between Users</h4>
    <p>In the event of a dispute, Users are invited to make every effort to reach an amicable and peaceful settlement of their differences.</p>
    <p>Failing this, each User may contact Blush's customer support to report any difficulties encountered. The solution proposed by Blush will be binding on the parties.</p>

    <h3>Article 13: COMPENSATION</h3>
    <p>Blush (and its assignees) reserves the right to sue the User in order to be compensated for any direct or indirect damage suffered as a result of the violation of these terms and conditions.</p>

    <h3>Article 14: PERSONAL DATA</h3>
    <p>Personal information provided by the User is processed in strict accordance with Blush's Personal Data Protection Charter, which provides details of the User's rights regarding his or her personal data and which forms an integral part of these terms of use.</p>
    <p>The terms "Personal Data" are defined in this Charter, accessible here.</p>
    <p>The User is invited to read them carefully before communicating his personal data and validating his registration.</p>

      <h3>Article 15: CONTENT MODERATION</h3>
    <p>All content submitted to the Platform will undergo manual moderation by our dedicated moderation team. Each submission will be carefully reviewed to ensure compliance with our General Terms of Use. The moderation process includes the following steps:</p>
    <h4>1. Initial Review</h4>
    <p>Upon submission, content is placed in a queue for review. Our moderation team will examine the content to determine whether it adheres to our standards, focusing on the prevention of illegal activities, non-consensual image use, hate speech, harassment, or any other violation of our terms.</p>
     <h4>2. Content Assessment</h4>
    <p>During this review, moderators will assess the appropriateness of the content based on criteria such as legality, respect for individuals, and compliance with community guidelines.</p>
     <h4>3. Decision Making</h4>
    <p>Once the assessment is complete, the moderation team will take one of the following actions:</p>
    <ul>
    <li>Approval: If the content complies with the platform’s guidelines, it will be approved and made available on the Platform.</li>
    <li>Modification Request: If the content partially violates the guidelines, the creator may be asked to modify the submission before it can be approved.</li>
    <li>Rejection: If the content is found to violate our terms, it will be rejected and will not appear on the Platform. The creator will be notified of the reason for rejection.</li>
    </ul>
  




    <p>Blush ensures that all submitted content is thoroughly reviewed within a reasonable timeframe. However, given the manual nature of the process, delays may occur during periods of high submission volume. We are committed to maintaining a safe and respectful environment for all users through this moderation process.Blush reserves the right to refuse, modify, or remove any content that does not comply with these standards. Although we aim to moderate all content promptly, the moderation process may vary depending on the nature of the content and the volume of submissions.</p>
   

    <h3>Article 16: INTELLECTUAL PROPERTY</h3>

    <h4>16.1. Intellectual property owned by Blush</h4>
    <p>The User acknowledges that Blush holds intellectual property rights to the Platform and waives any right to contest these rights in any form whatsoever.</p>
    <p>Thus, all trademarks, trade names, logos, slogans, designs and other distinctive signs or characters, animations, graphics, software solutions, source code, texts, site layout, etc., with the exception of the Creators' creations, are the exclusive intellectual property of Blush and may not be reproduced, used or represented in any way without Blush's written authorization.</p>
    <p>In other words, the User agrees not to:</p>
    <ul>
        <li>Reproduce, copy and/or represent the Platform or its elements and contents;</li>
        <li>Adapt, modify, translate or attempt to create derivative works from the Platform or its elements and content;</li>
        <li>Reverse engineer all or part of the Platform, or decompile or disassemble it;</li>
        <li>Exploit the Platform in any way whatsoever (licensing, distribution, broadcasting, sale, rental, etc.);</li>
        <li>Extract or attempt to extract materials from the Platform or Blush's database;</li>
        <li>Automatically extract data from the Platform, notably by means of a script or dedicated program (notably by scraping).</li>
    </ul>
    <p>Failing this, Blush may take any legal action necessary to preserve and restore its rights and to put an end to the infringement.</p>
    <p>The User's attention is drawn to the fact that the representation and copying, in whole or in part, of the Platform and its contents is prohibited and constitutes an infringement punishable by articles L.335-2 et seq. and L.713-1 et seq. of the French Intellectual Property Code.</p>
    <p>In general, any use of the elements that make up the service and the identity of Blush, must be subject to the express agreement of Blush.</p>
    <p>The User is informed that additional warnings or restrictions may appear from time to time for certain elements of the Site which, where applicable, are in addition to the present.</p>
    <p>Excluded from the scope of this article is all User Content, the ownership of which is described in articles 14.2. and 14.3 below.</p>

    <h4>16.2. Intellectual property owned by the Creators</h4>
    <p>Creators' creations are Lives.</p>
    <p>The Creators retain full and complete exclusive ownership.</p>
    <p>Blush, in its capacity as host, does not control or guarantee the Creators' creations, nor does it hold any rights thereto.</p>
    <p>The broadcasting of the Lives, and the participation of the Spectators, results in the Creator granting a license of use to the Spectator, under the conditions set out in the General Terms and Conditions of Sale (Part II hereof - article 16.3).</p>
    <p>The Creators warrant that they hold all intellectual property rights to their creations in order to guarantee peaceful distribution, such that Blush may never be held liable for any act of counterfeiting or infringement of image rights resulting from a Creator's creation.</p>

    <h2>PART III // TERMS AND CONDITIONS</h2>

    <h3>Article 17: PRESENTATION OF Blush'S OFFER</h3>

    <h4>17.1. Lives</h4>
    <p>The Blush Platform enables Creators to broadcast paid Lives to Spectators.</p>
    <p>The Creator has the choice of offering Spectators:</p>
    <ul>
        <li>An "entry ticket" pricing system, which involves paying to take part in Live;</li>
        <li>A "by the minute" pricing system, based on the amount of time the spectator spends in front of the Creator's Live show.</li>
    </ul>

    <h4>17.2. Replays</h4>
    <p>The Creator can record his Lives in order to offer them as Replays, directly via Blush. In this case, pricing is governed by the following rules:</p>
    <ul>
        <li>If Live had been priced on a "pay-per-view" basis, the same pricing policy will apply to Replay, with an identical amount;</li>
        <li>If Live had been priced "per minute", the Creator will have to set a fixed price for access to Replay.</li>
    </ul>

    <h4>17.3. Grant of a license to view the Creations</h4>
    <p>In accordance with the provisions of article 15.2. hereof, the Designs are the exclusive, full and complete property of their Creator.</p>
    <p>By paying the price, the Spectator is granted a temporary and very limited, exclusive, non-assignable and non-transferable license to view the Creation.</p>
    <p>The Spectator is therefore not granted any intellectual property rights other than the right to view.</p>
    <p>As a result,</p>
    <ul>
        <li>Any representation, total or partial reproduction of the Creation will constitute an infringement and will be sanctioned as such;</li>
        <li>The Spectator undertakes to use the Creations for personal purposes only and never for professional or commercial purposes;</li>
        <li>Not to publish, distribute, capture, by any means whatsoever, on any medium whatsoever, all or part of the Creation;</li>
        <li>Do not modify, adapt or alter the Creation.</li>
    </ul>
    <p>In the event of infringement of the rights of the Creator or Blush, the Viewer's Profile may be deleted and legal action may be taken to seek the cessation of the infringement and compensation for the related damage.</p>

    <h4>17.4. Sponsorship</h4>
    <p>In accordance with article 6.4, a sponsorship program is available for Creators.</p>
    <p>In the event of the use of a sponsorship link, the Creator will receive a commission equivalent to 5% of his Recommendee's income, under the same conditions as those set out in articles 18.2 and 18.3. and for a period of 36 months.</p>

    <h3>Article 18: PAYMENT BY THE ATTENDANT</h3>

    <h4>18.1. The price</h4>
    <p>Prices are displayed in dollars and inclusive of all taxes, so they may already include Value Added Tax if the User is subject to it.</p>
    <p>The price of the Live or Replay is set by the Creator.</p>

    <h4>18.2. Means of payment</h4>
    <p>Payments are made via Stripe.</p>
    <p>It is the responsibility of the Spectator to read and validate the General Conditions of this payment operator.</p>

    <h4>18.3. Time of payment</h4>
    <p>The Spectator must make payment in order to finalize his or her registration for the Live event and ensure that he or she will be able to take part. Confirmation of payment will be sent to the e-mail address associated with their Profile.</p>

    <h4>18.4. Claims and refunds</h4>
    <p>The Spectator who wishes to request a refund for the registration to a Live or Replay must send an e-mail to Blush with the subject "CLAIM - REQUEST FOR REFUND" within a maximum of 7 days after the Live.</p>
    <p>In order for the request to be considered, the Spectator must indicate the reasons which, in his or her opinion, justify a refund.</p>
    <p>The only valid reasons are:</p>
    <ul>
        <li>Live cancellation;</li>
        <li>Unavailability of Live due to Blush or the Creator;</li>
        <li>Technical malfunction (no sound, no image, etc.).</li>
    </ul>
    <p>The analysis of the merits of the request will be left to the discretion of Blush, which may, depending on the malfunction and its importance, grant the request for reimbursement or propose a partial refund, in proportion to the malfunction.</p>
    <p>No request for reimbursement will be entertained for reasons relating to the quality, subject matter or content of the Live or Replay.</p>

    <h3>Article 19: BILLING OF Blush SERVICES TO CREATORS</h3>

    <h4>19.1. Pricing of Blush Services</h4>
    <p>Blush's Services will be invoiced at 20% of the amount exclusive of tax, as set forth in article 17.1, after deduction of the payment operator's fees of 4% applied to the price inclusive of tax.</p>
    <p>Formula:</p>
    <p>(Amount incl. VAT - operator's fees applied to the amount incl. VAT) x 20%</p>
    <p>Example:</p>
    <p>The Creator broadcasts a Live show with an entrance fee of 120 euros including VAT, i.e. 100 euros excluding VAT. The payment operator's charges will be around 4% applied to 120 euros, i.e. 4.8 euros.</p>
    <p>The cost of Blush's Service will therefore be 19.04 euros, according to the following formula: (120 - 20 - 4.8) x 20% = 19.04, and the Creator's pre-tax profit will be 76.16 euros.</p>

    <h4>19.2. Retrocession of fees</h4>
    <p>Blush collects the price paid by Spectators to attend the Lives and sequesters these sums for 7 days after the Lives.</p>
    <p>At the end of this period, unless claims have arisen, Blush will retrocede the fees due to the Creator, in accordance with the pricing set forth in article 18.1.</p>
    <p>In the event of claims, as referred to in article 17.4, the Creator delegates to Blush the management of said claims and leaves Blush sole judge of their validity. The Creator undertakes to comply with Blush's decision whether or not to reimburse the Spectators concerned.</p>

    <h4>19.3. Billing mandate</h4>
    <p>The Creator hereby authorizes Blush to issue the invoice relating to each fee retrocession, in its name and on its behalf, on the basis of the information communicated by the latter.</p>
    <p>The Creator undertakes to reply to Blush, within 48 hours, for all questions relating to invoicing and to the mandate given herein.</p>
    <p>Failing this, a penalty of xxx% of the sums due to the Creator will be applied, by direct and automatic deduction from future invoice.</p>

    <h4>19.4. Tax and social security treatment</h4>
    <p>The fact that the Creator generates income via the Platform entails social and tax obligations with which the Creator must comply and for which Blush cannot be held liable.</p>
    <p>The Creator is invited to consult government websites, in particular www.impots.gouv.fr and www.urssaf.fr, for information on his or her obligations with regard to the declaration and payment of taxes.</p>
    <p>Blush therefore recommends all Creators to find out all they can about these issues and, if necessary, to contact the relevant authorities and/or seek specialist advice.</p>
    <p>Under no circumstances shall Blush be held liable for any improper tax or social security treatment of amounts generated by the Creator through Blush's Services.</p>

   



</div>

    </>
}