import React, { useEffect } from "react";



const ForgotPasswordSuccess = (props : any) => {


    return <>
        <p>We have sent you an e-mail to reset your password.</p>
        <p>If you don’t receive an email from us, please check your spam folder or contact customer support..</p>
        <p>Weerlive team</p>
    </>
};

export default ForgotPasswordSuccess;
