import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useContext, useState } from "react";
import socketContext from "../context/socket-context";

export interface MessageModalProps{
    visible : boolean;
    onMessageDone: Function;
}

export default function MessageModal(props: MessageModalProps){
    const socket = useContext(socketContext);

    const [messageContent, setMessageContent] = useState<string>("");
    const [destination, setDestination] = useState<string>("all");

    

    return <Modal isOpen={props.visible} centered={true}>
    <ModalHeader>
        Envoyer un message
    </ModalHeader>
    <ModalBody>
        <FormGroup>
                <Label for="explain">Message à envoyer</Label>
                <select onChange={(event) => {
                    setDestination(event.target.value)
;                }}>
                    <option value="all">All</option>
                    <option value="creator">Creator</option>
                    <option value="viewer">Viewer</option>
                </select>

                <textarea id="explain" style={{width: "100%", height: 100}} onChange={ (e) => (
                    setMessageContent(e.target.value)
                )} value={messageContent} /> 

        </FormGroup>
    </ModalBody>
    <ModalFooter>
        <Button color="primary" onClick={ () => {
            socket.emit('admin-message', {to: destination, content: messageContent});
            setMessageContent("")
            setDestination("all");
            
            props.onMessageDone();

        }}>Envoyer</Button>
        <Button color="primary" onClick={ () => {
            props.onMessageDone();
            setMessageContent("")
            setDestination("all")

        }}>Annuler</Button>
    </ModalFooter>
</Modal> 
}