import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import authService from './services/auth-service'
import { UpdateProfileDTO } from './dto/update-profile.dto'
import { UpdateCreatorProfileDTO } from './dto/update-creator-profile.dto'
import { UpdateCreatorSettingsDTO } from './dto/update-creator-settings.dto'
import logger from '../../common/logger'


interface PaginatedState<T> {
  data: T[];
  total: number;
  page: number;
  limit: number;
  loading: boolean;
  userName?: string; // Nouveau champ pour stocker le filtre de recherche
  email?: string;    // Nouveau champ pour stocker le filtre de recherche
}

export interface CompanyDetails {
    companyName: string,
    companyIdNumber: string
    vatOption: number,
    vatNumber?: string,
    owner: string,
    country: string
    address: string,
    postCode: string,
    city: string,
    accountNumber: string,
    swift : string,
    transitNumber?: string,
    institutionNumber?: string,
    bsb?: string,
  
}

export interface User{
    email: string,

    firstName?: string,
    lastName?: string,
    userName: string, 
    picture?: string,
    isAdmin?: boolean,
    createdAt: Date,
    color?: string,
    profileComplete?: boolean,
    shortId?: string,
    profile?: {
      firstName: string,
      lastName: string,      
      category: string,
      picture: string,  
      description?: string,
      phone?: string,
      bankAccount?: string,
      tiktok?: string;
      youtube?: string;
      instagram?: string;
      twitter: string;
      isLive?: boolean;
      companyDetails?: {
        companyName: string,
        companyIdNumber: string
        vatOption: number,
        vatNumber?: string,
        owner: string,
        country: string
        address: string,
        postCode: string,
        city: string,
        accountNumber: string,
        swift : string,
        transitNumber?: string,
        institutionNumber?: string,
        bsb?: string,
      }
    }
    country: string;

    _id: string

}

interface AuthenticatedUser{
    user: User,
    token: string,
}

interface LoginState {
  user?: User,
  token?: string,
  error?: string,
  success?: boolean,
  activated?: boolean,
  users: PaginatedState<User>;
  creators: PaginatedState<User>;
}



export const listUsers = createAsyncThunk(
  'user/list',
  async ({ page = 1, limit = 10, userName, email }: { page?: number; limit?: number; userName?: string; email?: string }) => {
    const response = await authService.list({ page, limit, userName, email });
    return { data: response.users, page, limit, total: response.total };
  }
);

export const listCreators = createAsyncThunk(
  'user/creators',
  async ({ page = 1, limit = 10, userName, email }: { page?: number; limit?: number; userName?: string; email?: string }) => {
    const response = await authService.listCreators({ page, limit, userName, email });
    return { data: response.users, page, limit, total: response.total };
  }
);

export const activate = createAsyncThunk('login/activate', async ({id} : {id: string}) => {
  const response = await authService.activate(id)    
  return response
})


export const login = createAsyncThunk('login/login', async ({email, password} : {email: string, password: string}) => {
  logger.info('Try login with email: ' + email)
  const response = await authService.login(email, password)    
  return response
})

export const me = createAsyncThunk('user/me', async ({email, password} : {email: string, password: string}) => {
  const response = await authService.me()
  return response.data
})

export const uploadProfilePicture = createAsyncThunk('user/updatePicture', async (file : File) => {
  const response = await authService.uploadProfilePicture(file)    
  return response.data
})


export const update = createAsyncThunk('user/update', async (user: UpdateProfileDTO) => {
  const response = await authService.update(user)    
  return response.data
})


export const updateCreatorProfil = createAsyncThunk('creator/updateProfil', async (profil: UpdateCreatorProfileDTO) => {
  const response = await authService.updateCreatorProfil(profil)    
  return response.data
})

export const updateCreatorSettings = createAsyncThunk('creator/updateSettings', async (profil: UpdateCreatorSettingsDTO) => {
  const response = await authService.updateCreatorSettings(profil)    
  return response.data
})

export const updatePassword = createAsyncThunk('creator/updatePassword', async (password: string) => {
  const response = await authService.updatePassword(password)    
  return response.data
})

export const deleteUser = createAsyncThunk('user/delete', async (password: string) => {
  const response = await authService.delete(password)    
  return response.data
})






const initialState = { 
  user: undefined,   
  users: { data: [], total: 0, page: 1, limit: 10, loading: false },
  creators: { data: [], total: 0, page: 1, limit: 10, loading: false } 
} as LoginState

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<AuthenticatedUser>) {
      state.user = action.payload.user
      state.token = action.payload.token
    },
    cleanMessage(state, action: PayloadAction<undefined>) {
      state.success = undefined
      state.error = undefined
    },
    cleanUser(state, action: PayloadAction<undefined>) {
      state.user = undefined
    },
    setIdentityValidationState(state, action: PayloadAction<any>) {
      
    },
    setUserData(state, action: PayloadAction<User>) {
      state.user = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action: PayloadAction<AuthenticatedUser>) => {
       logger.info('Login successfull ' + action.payload.user.userName)

        state.token = action.payload.token;
        state.user = action.payload.user;
      });
    builder.addCase(login.rejected, (state, action) => {
      logger.info('Issue with trying to login: ' + action.error.message)

      state.user = undefined;
      state.error = action.error.message;
    });  
    builder.addCase(activate.fulfilled, (state, action: PayloadAction<any>) => {
      state.activated = true;
    });
    builder.addCase(activate.rejected, (state, action) => {
      state.activated = false;
      state.error = action.error.message;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(update.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.success = true; 
    });
    builder.addCase(updateCreatorProfil.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.success = true; 
    });
    builder.addCase(updateCreatorProfil.rejected, (state, action) => {
      state.error = action.error.message
      state.success = false; 
    });
    builder.addCase(updateCreatorSettings.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.success = true;
    });    
    builder.addCase(uploadProfilePicture.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(uploadProfilePicture.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.success = true; 
    });    
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.success = true;
    });    
    builder.addCase(updateCreatorSettings.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.user = undefined;
    });        

    builder.addCase(listUsers.pending, (state) => {
      state.users.loading = true;
    });
    builder.addCase(listUsers.fulfilled, (state, action) => {
      if (action.payload.page === 1) {
        // Si la page est 1, remplacez les anciens résultats
        state.users.data = action.payload.data;
        console.log('data', action.payload)
      } else {
        // Sinon, ajouter les nouveaux résultats
        state.users.data = [...state.users.data, ...action.payload.data];
      }
      state.users.page = action.payload.page;
      state.users.limit = action.payload.limit;
      state.users.total = action.payload.total;
      state.users.loading = false;
      state.users.userName = action.meta.arg.userName || ''; // Mémoriser le filtre utilisé
      state.users.email = action.meta.arg.email || '';       // Mémoriser le filtre utilisé
    });
    builder.addCase(listUsers.rejected, (state, action) => {
      state.error = action.error.message;
      state.users.loading = false;
    });

    // Gestion de la liste des créateurs avec recherche
    builder.addCase(listCreators.pending, (state) => {
      state.creators.loading = true;
    });
    builder.addCase(listCreators.fulfilled, (state, action) => {
      if (action.payload.page === 1) {
        // Si la page est 1, remplacez les anciens résultats
        state.creators.data = action.payload.data;
      } else {
        // Sinon, ajouter les nouveaux résultats
        state.creators.data = [...state.creators.data, ...action.payload.data];
      }
      state.creators.page = action.payload.page;
      state.creators.limit = action.payload.limit;
      state.creators.total = action.payload.total;
      state.creators.loading = false;
      state.creators.userName = action.meta.arg.userName || ''; // Mémoriser le filtre utilisé
      state.creators.email = action.meta.arg.email || '';       // Mémoriser le filtre utilisé
    });
    builder.addCase(listCreators.rejected, (state, action) => {
      state.error = action.error.message;
      state.creators.loading = false;
    });
  }
})

export const {  setUser, cleanMessage, cleanUser, setUserData } = loginSlice.actions
export default loginSlice.reducer