import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";


export function Partner(){
    const {partner} = useParams();
    const navigation = useNavigate();

    useEffect(() => {
        if (partner){
            localStorage.setItem("partner", partner);
            const timeout = setTimeout(() => {
                navigation("/creator");
            }, 1000);
        }
    }, [partner]);

    return <></>  
}