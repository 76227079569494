
import { getPlaformImage } from '../../../platforms/config';
import "./login-left-panel.css";


const logo = getPlaformImage('logo-text.svg');
const keywords = getPlaformImage('weerlive-keywords.svg');
const welcomeBadge = getPlaformImage('welcome-badge.svg');


export default function LoginLeftPane() {
    return <div className="login-left-panel">
        <img src={welcomeBadge} className="welcome-badge" alt="Welcome Badge"/>
        <img src={logo} alt="WeLive" />
        <img src={keywords} className='keywords' alt="WeLive" />
    </div>
}