import { createContext, useState } from 'react';

const ALERT_TIME = 5000;
const initialState = {
  text: '',
  type: '',

};

export enum AlertType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'danger',
}

const AlertContext = createContext({
  ...initialState,
  setAlert: (text: string, type: AlertType)  => {},
});

export const AlertProvider = ({ children } : { children : React.ReactElement}) => {
  const [text, setText] = useState('');
  const [type, setType] = useState('');
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const setAlert = (text: string, type: AlertType) => {
    setText(text);
    setType(type);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(setTimeout(() => {
      setText('');
      setType('');
    }, ALERT_TIME));
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        type,
        setAlert
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
