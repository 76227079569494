import { useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../../common/api";
import { Button, Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getEvent } from "../event-slice";
import EventImage from "../components/event-image";
import { EventState } from "../dto/event.dto";
import { I18nLink } from "../../../theme/components/I18nLink";
import { Trans } from "react-i18next";
import { getConfig } from "../../../platforms/config";

export function CheckoutEventSuccess() {
    const { id } = useParams();
    const event = useAppSelector((state) => state.events.event);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if (getConfig().name === "weerlive" && id && !event){
          dispatch(getEvent(id));
        }
    }, [id]);

    useEffect(() => {
         if (!event && getConfig().name === "blush"){
            const url = new URL(window.location.href);
            const custom2 = url.searchParams.get('custom2')
            if (custom2){
                dispatch(getEvent(custom2));
            }
        }
    }, []);
    

    useEffect(() => {
        
        const url = new URL(window.location.href);
        
        if (getConfig().name == "weerlive"){
            const secretKey = url.searchParams.get('payment_intent')
            const secretKeySetup = url.searchParams.get('setup_intent')
    
            if (event && (secretKey || secretKeySetup)){
                api.post(`events/${id}/checkout/success`, {
                    paymentIntentID: secretKey,
                    setupIntentID: secretKeySetup
    
                  });        
              }
        }

    }, [event]);


    return  <>
        { event && <Row className="justify-content-md-center"><Col md={7}>
            
            {(event.state == EventState.Draft || event.state == EventState.Live) && <><h1 style={{textAlign: "center", marginBottom: 30}}>
            <Trans i18nKey={"checkout.validate.title"}>Your registration is validated</Trans></h1>
                {event.state !== EventState.Live && <p style={{textAlign: 'center'}}><Trans i18nKey={"checkout.validate.notif"}>Nous vous enverrons un mail de rappel 1h avant l’évènement.</Trans></p>}
            </>}
            { event.state == EventState.Vod && <>
                <h1 style={{textAlign: "center", marginBottom: 30}}>
                <Trans i18nKey={"checkout.validate.vod"}>L'achat de la VOD est réussi</Trans></h1>
            </>}  
            <div style={{ display: "flex", marginBottom: 20}}>
                <EventImage title={event.title} image={event.picture}  event={event} color={event.color}/>
            </div>
            <div style={{fontSize: '18px', fontWeight: 600, lineHeight: '20px', marginBottom: 30}}>
                {event.title} 
            </div>
            {/* {event.state !== EventState.Live && event.state !== EventState.Vod && <Button color="primary" onClick={ (event) => {
                atcb_action(config, event.target as any);
            }}>Ajouter au calendrier</Button> }  */}
            
            
            {event && event.state === EventState.Live && <I18nLink to={`/studio/${event.shortId}`}><Button color="primary"><Trans i18nKey={"checkout.validate.access_live"}>Accéder au live</Trans></Button></I18nLink>}
            {event && event.state === EventState.Vod && <I18nLink to={`/vod/${event.shortId}`}><Button color="primary"><Trans i18nKey={"checkout.validate.access_vod"}>Accéder à la VOD</Trans></Button></I18nLink>}
            {event && event.state !== EventState.Live && event.state !== EventState.Vod && <I18nLink to={`/viewer/overview`}><Button color="primary"><Trans i18nKey={"checkout.validate.return_dashboard"}>Retourner au dashboard</Trans></Button></I18nLink>}

        </Col></Row>
        }
        </>
}