import { Button, Col, Row, Spinner } from "reactstrap";
import { EventCard } from "../../event/components/event-card";
import { useEffect, useState } from "react";
import { listEventByCategory, listEventsByCreator, listMyEvents, listMyEventsNext, listNextEventByCategory } from "../../event/event-slice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Link, NavLink, useNavigate, useNavigation, useParams } from "react-router-dom";
import { getOverview, getStats } from "../creator-slice";
import DateFilter from "../components/date-filter";
import { EventState } from "../../event/dto/event.dto";
import { Trans } from "react-i18next";
import useModal from "../../../theme/hooks/use-modal";
import { CreateLiveLink } from "../components/create-live-link";
import Loading from "../../../theme/components/loading";
import { LiveSelector } from "../../../theme/components/live-selector";

export function Overview(){
    const user = useAppSelector((state) => state.login.user);
    const events = useAppSelector((state) => state.events.events);
    const eventsLoading = useAppSelector((state) => state.events.loading);
    const categories = useAppSelector((state) => state.events.categories);
    const overview = useAppSelector((state) => state.creator.overview);
    const statsLoading = useAppSelector((state) => state.creator.loading);
    const currentFilter = useAppSelector((state) => state.creator.currentFilter);
    
    const [category, setCategory] = useState<string>("all");
    const [filter, setFilter] = useState<string>("live");
    
    const loading = useAppSelector((state) => state.events.loading);
    const count = useAppSelector((state) => state.events.eventCount);
    const next = useAppSelector((state) => state.events.eventNext);
    
    const modal = useModal();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    useEffect(() => {
      if (user){
        dispatch(listMyEvents({category, filter}));
        dispatch(getOverview({startDate: undefined, endDate: undefined}));
      }
    }, [user]);

    return <div>
        <Row className="align-content-center">
            <Col>
                <h1 style={{fontSize: 28}}><Trans i18nKey={"backoffice.menu.overview"}>Overview</Trans></h1>
            </Col>
            { /* <DateFilter active={currentFilter} /> */ }

        </Row>
        <Row style={{marginTop: 10}}>
             <Col md={12} xl={7} >
                { statsLoading ? <Loading /> :
                <Row className="secondary align-content-center text-center" style={{marginBottom: 10, borderRadius: 20, padding: 10, minHeight: 125}}>
                    <Col md={{size: 3}} xs={{size: 6}} style={{}}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.overview.stream"}>Stream</Trans></h2>
                        <h3>{ overview?.liveCount } </h3>
                    </Col>
                    <Col md={{offset: 1, size: 3}} xs={{size: 6}} style={{}}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.overview.views"}>Views</Trans></h2>
                        <h3>{ overview?.views } </h3>
                    </Col>
                    <Col md={{offset: 1, size: 3}} xs={{size:12}}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.overview.revenue_live"}>Revenu Live</Trans></h2>
                        <h3>{ overview?.total.toFixed(2) } $</h3>
                    </Col>
                </Row>}
            </Col> 
            <Col className="secondary align-content-center text-center" style={{borderRadius: 20, padding: 20, height: 120}} xl={{
                offset: 1,
                size: 4
            }}>
                <h2 style={{fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.overview.create_next_live"}>Créez ou programmez votre prochain live</Trans></h2>
                <CreateLiveLink>
                    <Button color="primary"><Trans i18nKey={"backoffice.creator.overview.create_live"}>Créer un live</Trans></Button>
                </CreateLiveLink>
            </Col>
        </Row>    
        <Row>
        <LiveSelector xl={6} hideCategories={true} categories={categories} states={[]} category={category} filter={filter} loading={loading} events={events} count={count} next={next} onCategoryChange={(category) => {
                setCategory(category);
                dispatch(listMyEvents({category, filter}))
            }} onStateChange={(filter) => {
                setFilter(filter);
                dispatch(listMyEvents({category: category, filter: filter}))
            }} loadNext={(category, filter, offset) => {
                dispatch(listMyEventsNext({category, filter, offset}))
            }}/>
        </Row>    

    </div>
}