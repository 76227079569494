import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Cropper, ReactCropperElement } from "react-cropper";
import { useRef } from "react";
import "cropperjs/dist/cropper.css";

interface ImageCropperModalProps {
    visible: boolean;
    onCrop: Function;
    onCancel: Function;
    image: String;
    aspectRatio?: number;
}

export default function ImageCropperModal(props: ImageCropperModalProps) {
    const cropperRef = useRef<ReactCropperElement>(null);

    const cropImage = () => {
        if (cropperRef.current) {

            cropperRef.current.cropper.getCroppedCanvas().toBlob((blob: Blob | null) => {
                if (blob) {
                    props.onCrop(blob);
                }
            });
        }
    }

    return <Modal size="xl" isOpen={props.visible} toggle={() => { props.onCancel() }}>
        <ModalHeader toggle={() => {}}>Crop Image</ModalHeader>
        <ModalBody style={{display: "flex", flex: 1}}>
        <Cropper
                ref={cropperRef}
                style={{ height: 400, width: "100%" }}
                initialAspectRatio={props.aspectRatio || 1}
                aspectRatio={props.aspectRatio || 1}
                preview=".img-preview"
                src={props.image as string}
                viewMode={1}
                guides={true}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                checkOrientation={true} 
            />
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={() => {
                cropImage();
            }}>Ok</Button>{' '}
            <Button color="secondary" onClick={() => {
                props.onCancel();
            }}>Cancel</Button>
        </ModalFooter>
    </Modal>
}