import React, { useEffect, useState } from "react";
import './revenue-simulator.css';
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Trans } from "react-i18next";
import Info from "../../../theme/components/info";
import { getConfig as getAppConfig } from "../../../platforms/config";
import { use } from "i18next";
import { getConfig } from "../../app/app-slice";

const calculateRevenue = (price: number, duration: number, attendees: number, config: any, isPerMinute: boolean) => {
  if (config == undefined) return 0;
  let revenue = price * attendees;
  if (isPerMinute){
    revenue = price * duration * attendees;
  }
  const transactionFee = revenue * config.transactionFeePercent;
  
  let revenueWithoutCommission = revenue - transactionFee  - ((revenue-transactionFee) * config.commissionPercent) * 1000 /1000;
  return revenueWithoutCommission;
}



export default function RevenueSimulator() {
  const [duration, setDuration] = useState(30);
  const [price, setPrice] = useState(1);
  const [attendees, setAttendees] = useState(10);
  const [type, setType] = useState<string>("event");
  const dispatch = useAppDispatch();
  const config = useAppSelector((state) => state.app.config);
  useEffect(() => {
    dispatch(getConfig());
  }, []);
  
  useEffect(() => {
    setRevenue(calculateRevenue(price, duration, attendees, config, true));
  }, [config]);

  const [revenue, setRevenue] = useState(calculateRevenue(1, 30, 10, config, true));


  const updatePrice = (price: number, duration: number, attendees: number, isPerMinute: boolean, config: any) => {
    setRevenue(calculateRevenue(price, duration, attendees, config, isPerMinute));
  }

  useEffect(() => {
    updatePrice(price, duration, attendees, false, config);
  }, [config]);
  
  return <div className="RevenueSimulator">
    <span><Trans i18nKey="revenueSimulator.amount">Avec un tarif de </Trans><input type="text" value={price} onChange={ (event : React.ChangeEvent<HTMLInputElement>) => {
      const price = parseFloat(event.target.value);
      if (!isNaN(price) && price > 0) {
        setPrice(price);
        updatePrice(price, duration, attendees, type === "minute", config);
      }else {
        setPrice(0);
        updatePrice(0, duration, attendees, type === "minute", config);
      }
    }}></input> <Trans i18nKey="revenueSimulator.each">$ par</Trans> { getAppConfig().name == "weerlive" ? <select onChange={ (e) => {setType(e.target.value); updatePrice(price, duration, attendees, e.target.value == "minute", config)}}><option value="event"><Trans i18nKey="revenueSimulator.event">évènement</Trans></option><option value="minute"><Trans i18nKey="revenueSimulator.minute">minute</Trans></option></select> : <Trans i18nKey="revenueSimulator.event">évènement</Trans>}</span> 
    <span>{type == "minute" && <><Trans i18nKey="revenueSimulator.live">pour un live de</Trans> <input value={duration} type="text" onChange={ (event : React.ChangeEvent<HTMLInputElement>) => {
      const duration = parseFloat(event.target.value);
      if (!isNaN(duration) && duration > 0) {
        setDuration(duration);
        updatePrice(price, duration, attendees, type === "minute", config);
      }else {
        setDuration(0);
        updatePrice(price, 0, attendees, type === "minute", config);
      }
    }}></input> <Trans i18nKey="revenueSimulator.minutes">minutes</Trans></>} <Trans i18nKey="revenueSimulator.with">with</Trans> <input type="text" value={attendees} onChange={(event : React.ChangeEvent<HTMLInputElement>) => {
      const attendees = parseInt(event.target.value);
      if (!isNaN(attendees) && attendees > 0) {
        setAttendees(attendees);
        updatePrice(price, duration, attendees, type === "minute", config);
      }else {  
        setAttendees(0);
        updatePrice(price, 0, attendees, type === "minute", config);
  
      }
    }}></input> <Trans i18nKey="revenueSimulator.viewers">participants</Trans></span>
    <span> <Trans i18nKey="revenueSimulator.receive">recevez</Trans> <span className="attention">{revenue} $</span><Info style={{top: -20}} message="Ceci est un message très long Ceci est un message très long Ceci est un message très long Ceci est un message très long Ceci est un message très long Ceci est un message très long" /></span>
  </div>;
}