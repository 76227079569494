import { useState } from "react";
import { ConnectedUser } from "../dto/connected-user.dto";
import HeaderWidget from "./header-widget";
import { set } from "lodash";

interface CommunityListProps {
    name: string;
    icon: string;
    color: string;
    users: any[];
}

export default function CommunityList(props: CommunityListProps) { 
    const [show, setShow] = useState<boolean>(true);

    return <><HeaderWidget name={props.name} icon={props.icon} show={show} color={props.color} onToggleShow={ () => {
        setShow(!show);
    }}/>                     
        { show && <>
            { props.users }
        </>}
    </>
}