import { ReactElement, createContext, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


export interface ModalState {
    title: string;
    content: ReactElement;
    buttons: ModalButton[];
};


const initialState : ModalState = {
  title: '',
  content: <></>,
  buttons: [],
};

export interface ModalButton{
    label: string,
    onClick: () => void,
} 


const ModalContext = createContext({
  ...initialState,
  show: (title: string, content : ReactElement, buttons: ModalButton[])  => {
    
  },
});

export const ModalProvider = ({ children } : { children : React.ReactElement}) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState<ReactElement>(<></>);
    const [buttons, setButtons] = useState<ModalButton[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    
    const show = (title: string, content : ReactElement, buttons: ModalButton[]) => {
      setTitle(title);
      setContent(content);
      setButtons(buttons);
      setVisible(true);
    };

  return (
    <ModalContext.Provider
      value={{
        title,
        content,
        buttons,
        show
      }}
    >   
        <Modal isOpen={visible} centered={true} style={{ border: 0, borderRadius: 50}}>
            <ModalHeader>
                {title}
            </ModalHeader>
            <ModalBody>
                { content }
            </ModalBody>
            <ModalFooter>
                { buttons.map((button, index) => {
                    return <Button key={index} onClick={ () => {
                      button.onClick();
                      setVisible(false);
                    }} color="primary">{button.label}</Button>
                    
                })}
            </ModalFooter>
        </Modal> 
      
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
