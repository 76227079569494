import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  FormFeedback,
} from "reactstrap";
import { login } from "../login-slice";
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../../logo.svg";
import * as yup from "yup"
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../theme/components/form-input";
import "./login.css"

type LoginValues = {
  email: string,
  password: string,
};

const LoginSchema = yup.object({
  email: yup.string().email().required("Email is Required"),
  password: yup.string().min(2).required("Password is Required"),
})

const Login = (props : any) => {
  const navigation = useNavigate();
  const user = useAppSelector((state) => state.login.user);
  const error = useAppSelector((state) => state.login.error);
  const message = useAppSelector((state) => state.app.message);

  const dispatch = useAppDispatch();


  const resolver = useYupValidationResolver(LoginSchema)
  const { register: _register, handleSubmit, formState: { errors } } = useForm<LoginValues>({resolver})

  const onSubmit: SubmitHandler<LoginValues> = (data: LoginValues) => {
    dispatch(login({ email: data.email, password: data.password }));
  }


  useEffect( () => {
    if (user) {
      let redirect = "/creator/overview";
      if (!user.profile){
        redirect = "/viewer/overview";
      }
      
      if (window.location.search) {
        const redirectSearch = new URLSearchParams(window.location.search).get("redirect");
        if (redirectSearch) {
          redirect = redirectSearch;
          
        }
      }
      navigation(redirect);

    }
    
    }, [user]);

  
  

  return (
      <div className="login">
              <Form onSubmit={handleSubmit(onSubmit)} className="align-middle">
              { error && <Alert color="danger">{error}</Alert> }  
                <FormGroup style={{marginTop: 25}} className="pb-2 mr-sm-2 mb-sm-0">
                  <Label for="formEmail" className="mr-sm-2">
                    Email
                  </Label>
                  <FormInput
                    type="email"
                    name="email"
                    id="formEmail"
                    placeholder="Email"
                    register={_register}
                    invalid={errors.email}
                  />
                  <FormFeedback>
                    { errors.email?.message }
                  </FormFeedback>                  
                </FormGroup>
                <FormGroup style={{marginTop: 25}} className="pb-2 mr-sm-2 mb-sm-0">
                  <Label for="formPassword" className="mr-sm-2">
                    Password
                  </Label>
                  <FormInput
                    type="password"
                    name="password"
                    id="formPassword"
                    placeholder="Password"
                    register={_register}
                    invalid={errors.password}
                  />
                  <FormFeedback>
                    { errors.password?.message }
                  </FormFeedback>
                </FormGroup>
                <div className="bottom-container"  style={{display: "block"}}>
                  <div  style={{marginBottom: 10}}>
                  <Button type="submit" color="primary">
                    Login In now
                  </Button>
                  </div>
                  <div>
                  <NavLink to={'/forgot-password'}>Forgot your password ? </NavLink>
                  </div>
                </div>
              </Form>
              <p className="footer">
                Not a member yet? <NavLink to={'/create-profil'} className={"bold"}>Register now</NavLink>
              </p>

      </div>
  );
};

export default Login;
