import React, { useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import { EventCard } from "../../event/components/event-card";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { listEventsByCreator, listInvoices } from "../../event/event-slice";
import { Link, NavLink, useParams } from "react-router-dom";
import EventImage from "../../event/components/event-image";
import moment from "moment";
import BASE_URL from "../../../common/urls";
import { EventState } from "../../event/dto/event.dto";
import { Trans } from "react-i18next";

export default function Invoices() {
  const invoices = useAppSelector((state) => state.events.invoices);
  const dispatch = useAppDispatch();
  let { id } = useParams();

  useEffect(() => {
    dispatch(listInvoices());

  }, []);

  return <>
    <Row style={{marginBottom: 20}}>
                  <h2 style={{color: 'white', fontWeight: "bold", fontStyle: 'normal', marginBottom: 30}}><Trans i18nKey="backoffice.user.invoices.invoices">Mes factures</Trans></h2>

                  <table style={{borderCollapse: 'separate', borderSpacing: '0 30px'}}>
                    <tbody>
                         {invoices.map((invoice) => {
                            return <tr>
                                <td style={{maxHeight: 150, maxWidth:150, display: "flex"}}><EventImage image={invoice.event.picture} title={invoice.event.title} color={invoice.event.color}/></td>
                                <td><p>{invoice.event.title.substring(0, 65)} {invoice.event.title.length > 65 && "..." }</p></td>
                                <td><p>{ moment(invoice.createdAt).format("DD/MM/YYYY hh:mm:ss") }</p></td>
                                <td><p>{ invoice.minutes && <p>{ invoice.minutes } minutes</p>}</p></td>
                                <td>
                                <div><a target="_blank" href={BASE_URL +"/events/invoices/"+invoice._id+"/download"}><Trans i18nKey="backoffice.user.invoices.download">Download Invoice</Trans></a></div> - 
                                  { invoice.event.state == EventState.Vod && <div><NavLink to={"/vod/"+invoice.event._id}><Trans i18nKey="backoffice.user.invoices.watchReplay">Voir la VOD</Trans></NavLink></div> }
                                  { invoice.event.state == EventState.Live && <div><NavLink to={"/studio/"+invoice.event.shortId}><Trans i18nKey="backoffice.user.invoices.watchLive">Voir la live</Trans></NavLink></div>  }
                                </td>

                            </tr>
                         })}
                    </tbody>

                  </table>
    </Row>       

  </>
}