import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import { getPlaformAsset } from "./platforms/config";
import { fr } from 'yup-locales';
import { setLocale } from 'yup';

const translationEN = getPlaformAsset('i18n/translations/en.json');
const translationFR = getPlaformAsset('i18n/translations/fr.json');

setLocale(fr);

const getUserLanguage = () => (window.navigator as any).userLanguage || window.navigator.language;

// the translations
let resources = {
    en: {
      translation: translationEN
    },
} as any;

if (process.env.REACT_APP_PLATFORM == "weerlive"){
  resources['fr'] ={
    translation: translationFR
  }
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;