import React, { CSSProperties, useState } from "react"
import "./info.css"
import { Tooltip } from "reactstrap"

export interface InfoProps{
    message: string,
    style?: CSSProperties
}

export default function Info(props: InfoProps){
    const [showInfo, setShowInfo] = useState(false);
    const infoRef = React.createRef<HTMLSpanElement>();
    const popupRef = React.createRef<HTMLDivElement>();
    const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | undefined>();

    const show = () => {
        const doShowInfo = !showInfo;
        setShowInfo(doShowInfo);

        if (!doShowInfo){
            if (timeoutRef){
                clearTimeout(timeoutRef);
            }
        }else {
            
            if (timeoutRef){
                clearTimeout(timeoutRef);
            }
            setTimeoutRef(setTimeout(() => {
                setShowInfo(false);
            }, 3000));
        }

    }


    return <><a id="info-popup" className="information" onClick={show} style={props.style}>i</a><Tooltip
    setIsOpen={show}
    target={"info-popup"}
    isOpen={showInfo}
    className="info-popup"
    >{props.message}</Tooltip> </>
}