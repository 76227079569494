import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getEvent } from "../../event/event-slice";
import EventHeader from "../../event/components/event-header";
import { Button, ButtonGroup, ButtonToolbar } from "reactstrap";
import useModal from "../../../theme/hooks/use-modal";
import api  from "../../../common/api";

export default function AdminEvent() {
    const { id } = useParams();
    const event = useAppSelector((state) => state.events.event);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if ((id && event && event?.shortId != id) || id && !event){
            dispatch(getEvent(id));
        }
    }, [id, event]);
    
    const modal = useModal();

  return <>{event && 
        <div>
            <EventHeader event={event} />
            <h1>Statistiques</h1>
            <p>Nombre de vues: {event.viewerCount}</p>
            <p>Revenus Live: {event.revenueLive}</p>
            <p>Revenus VOD: {event.revenueVOD}</p>
            <h1>Participants</h1>
            <h1>Liste des inscrits ( {event.subscribers?.length} )</h1>
                {event.subscribers?.map( (participant) => {
                    return <div>
                        <p>{participant.userName}</p>
                    </div>
                }
            )}
            <h1>Liste des inscrits VOD ( {event.subscribersVod?.length} )</h1>
                
                {event.subscribersVod?.map( (participant) => {
                    return <div>
                        <p>{participant.userName}</p>
                    </div>
                }
            )} 

            Actions : 
            <ButtonToolbar>
                <ButtonGroup className="me-2">
                    <Button onClick={ () => {
                        modal.show("Annuler l'évènement", <>Etes-vous sûr de vouloir annuler l'évènement ?</>, [
                            {label: 'Confirmer', onClick: () => {
                                api.post(`/events/${event._id}/cancel`).then( () => {
                                    dispatch(getEvent(event._id));
                                });
                            }},
                            {label: 'Non', onClick: () => {}},
                        ]);
                    }} color="primary">Annuler l'évènement</Button>  
                </ButtonGroup>
                <ButtonGroup>
                    <Button color="primary" onClick={ () => {
                        modal.show("Rebourser les utilisateurs", <>Etes-vous sûr de vouloir rembourser les utilisateurs ?</>, [
                            {label: 'Confirmer', onClick: () => {

                                api.post(`/events/${event._id}/refund`).then( () => {
                                    dispatch(getEvent(event._id));
                                });

                            }},
                            {label: 'Non', onClick: () => {}},
                            
                        ]);
                    }}>Rebourser les utilisateurs</Button>  
                </ButtonGroup>
            </ButtonToolbar>
         
        </div>}
    </>;
}