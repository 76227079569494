import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getEvent } from "../event-slice";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button, Col, Row } from "reactstrap";
import EventImage from "../components/event-image";
import CreatorHeader from "../../creator/components/creator-header";
import { EventPaymentType, EventState } from "../dto/event.dto";
import useGeoLocation from "react-ipgeolocation";
import "./show-event.css"
import ButtonCTAEvent from "../components/button-cta-event";
import EventHeader from "../components/event-header";

export default function ShowEvent() {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.login.user);
    const config = useAppSelector((state) => state.app.config);
    const event = useAppSelector((state) => state.events.event);
    const [studioReady, setStudioReady] = useState(false);

    const {id} = useParams();

    useEffect (() => {
        if (id){
            dispatch(getEvent(id));
        }
    }, [id]);

    useEffect(() => {
        if (event && config){
            const studioReady = moment(event?.date).subtract(config?.studioAvailableBefore || 10, 'minutes').isBefore(moment());
            setStudioReady(studioReady);
        }

    }, [event, config]);
    return <>
        { event && <>
            <CreatorHeader creator={{ ...event.owner.profile, userName: event.owner.userName, picture: event.owner.picture, color: event.owner.color }}/>
            <div style={{marginTop: 100}}></div>
            <EventHeader  event={event} editable={true  } />
            </>
        }
    </>
}

