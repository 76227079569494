import { faArrowAltCircleDown, faCircleArrowDown, faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface HeaderWidgetProps {
    name: string;
    icon: string;
    onToggleShow: Function;
    show: boolean;
    color: string;

}
export default function HeaderWidget(props: HeaderWidgetProps) {
    return  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', marginBottom: 20, marginTop: 20}}>
        <div style={{backgroundColor: props.color, borderRadius: 5, display: "flex", width: 25, height: 25, alignItems: "center", justifyContent: "center", marginRight: 5}}>
            <img src={props.icon} style={{width: "60%"}}/> 
        </div>
        <span style={{fontWeight: 800}}>{props.name}</span>
        <FontAwesomeIcon onClick={ () => { props.onToggleShow() }} icon={props.show ? faCircleArrowUp : faCircleArrowDown } style={{marginLeft: 10, color: "#7580FC"}} />
</div>
}