import React, { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form"
import { useAppDispatch, useYupValidationResolver } from "../../../hooks";
import * as yup from "yup"
import authService from "../../login/services/auth-service";
import { useNavigate } from "react-router-dom";
import CreateForm, { CreateProfil } from "../../viewer/components/create-form";
import CompleteProfilForm, { CompleteProfilSchema } from "../components/complete-profil-form";
import CertifyProfilForm, { CertifyProfile } from "../components/certify-profil-form";
import { CertifyProfilSchema } from "../components/validate-profil-form";
import { t } from "i18next";


export default function CreateCreatorProfil() {

  const dispatch = useAppDispatch();
  const [user, setUser] =  useState<CreateProfil | undefined>();
  const [error, setError] = useState<string | undefined>(undefined);
  const navigation = useNavigate();
  const [profile, setProfile] = useState<CompleteProfilSchema>();

  const onCreateProfilSubmit: SubmitHandler<CreateProfil> = (data) => {
    authService.validateUser(data.country, data.userName, data.email, data.password).then( (response) => {
        if (response.status === 200 || response.status === 201) {
          setError(undefined);
          setUser(data);
        }else {
          setError(response.data.message);
        }
      }).catch( (error) => {
        setError(error.response.data.message);
      });
  }

  const onCompleteProfilSubmit: SubmitHandler<CompleteProfilSchema> = (data) => {

    setProfile(data);
  }

  const onCertifyProfilSubmit: SubmitHandler<CertifyProfile> = (data) => {
    let partner = localStorage.getItem("partner");
    const partnerId = partner ? partner : undefined;

    authService.registerCreator(user!.country, user!.userName, user!.email, user!.password, profile!.firstName, profile!.lastName, profile!.category, profile!.photo,  partnerId, data.tiktok, data.instagram,  data.youtube, data.twitter).then( (response) => {
      if (response.status === 200 || response.status === 201) {
        setError(undefined);
        navigation("/validation");
        localStorage.removeItem("partner");
      }else {
        setError(response.data.message);
      }
    }).catch( (error) => {
      setError(error.message);
    });
  };

  return <>
      { !user && <CreateForm onSubmit={onCreateProfilSubmit} error={error} submitLabel={t("form.next")} />}
      { user && ! profile && <CompleteProfilForm onSubmit={onCompleteProfilSubmit} error={error} submitLabel={t("form.next")} />}
      { user && profile && <CertifyProfilForm onSubmit={onCertifyProfilSubmit} error={error} submitLabel={t("form.validae_creator_account")} />}

  </>;
}