import { CSSProperties } from "react";
import BASE_URL from "../../../common/urls";
import "./creator-image.css";
import { styles } from "./date-filter";

interface CreatorImageProps{
    image?: string;
    userName: string;
    style?: CSSProperties
    backgroundColor?: string;
    isLive?: boolean;
    color?: string;
} 

export default function CreatorImage(props: CreatorImageProps){
    return <div className={`creator-image-container`} style={{backgroundColor: props.color, backgroundImage: props.image ? `url(${props.image})` : undefined}}  >
        { !props.image && <h1 style={{margin:0, padding:0}}>{props.userName.substring(0,1).toUpperCase()}</h1> }
        

    </div>
        
}