import React, { useEffect } from 'react';
import { useNavigate, useNavigation } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';

interface CreatorRequiredProps {
    children: JSX.Element;
}

export default function AdminRequired(props: CreatorRequiredProps) {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.login.user);
    const navigation = useNavigation();

    useEffect(() => {
        if (!user || user.isAdmin == false) {
            navigate(`/`);
        }
    }, [user]);
    
    return props.children;
}