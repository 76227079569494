import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth-service";
import { cleanUser, setUser } from "../login-slice";
import { useAppDispatch } from "../../../hooks";

export default function Logout() {
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    useEffect( () => {
      authService.logout().then( () => {
        dispatch(cleanUser());
        navigation("/");
      });
    }, []);

    return <></>
  }