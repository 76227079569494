import { useEffect, useState } from "react";
import "./timer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export interface TimerProps{
    viewers : number;
    liveStartedAt : Date;
} 

export default function Timer(props: TimerProps) {
    const [currentTime, setCurrentTime] = useState<string>("00:00:00");

    useEffect(() => {
        const timerInterval = setInterval(() => {
                const now = new Date();
                const startDate = new Date(props.liveStartedAt!);
                const diff = now.getTime() - startDate.getTime();
                const hours = Math.floor(diff / (1000 * 60 * 60));
                const minutes = Math.floor((diff - (hours * 1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff - (hours * 1000 * 60 * 60) - (minutes * 1000 * 60)) / 1000);

                let currentTime;
                
                
                if (hours < 10){
                    currentTime = `0${hours}`;
                }else {
                    currentTime = `${hours}`;
                }

                if (minutes < 10){
                    currentTime += `:0${minutes}`;
                }else {
                    currentTime += `:${minutes}`;
                }
   

                if (seconds < 10){
                    currentTime += `:0${seconds}`;
                }else {
                    currentTime += `:${seconds}`;
                }

                
                setCurrentTime(currentTime);
            }, 1000);
        
            return () => {
            clearInterval(timerInterval);
        }
    }, [props.liveStartedAt]);

    return  <div className="live-data">
        <span className="on-air">&nbsp;</span>
        <span style={{marginLeft: 10, marginRight: 20, fontSize: 12, color: "#DFDFDF"}}>{currentTime}</span>
        <FontAwesomeIcon style={{marginRight: 10}} icon={faEye} />
        <span style={{color: "#8A8A8A",  fontSize: 12, }}>{ props.viewers }</span>
    </div>

}