import { useEffect } from "react";
import { Onfido } from "onfido-sdk-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Trans } from "react-i18next";

interface IDValidationProps{
    token: string;
    onDone: () => void;
    onDismiss: () => void;
}

export const IDValidation = (props: IDValidationProps) => {
  useEffect(() => {
    Onfido.init({
      token: props.token,
      containerId: "onfido-mount",
      steps: ["welcome", "document", {
        type: "face",
        options: { requestedVariant: "motion" }
      } , "complete"],
      onComplete: function(data: any) { 
        props.onDone();
        
      }
    });
  }, []);

  return <Modal isOpen={true} centered={true} backdrop={true} toggle={ () => {
    props.onDismiss();
  }}>
    <ModalBody>
      <div id="onfido-mount"></div>
    </ModalBody>
  </Modal>
};