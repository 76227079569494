import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { listSubscribedEvents } from "../../event/event-slice";
import { Trans } from "react-i18next";
import { I18nLink } from "../../../theme/components/I18nLink";
import { LiveSelector } from "../../../theme/components/live-selector";

export function Overview() {
    const dispatch = useAppDispatch();
    const events = useAppSelector(state => state.events.events);
    const [category, setCategory] = useState<string>("all");
    const [filter, setFilter] = useState<string>("live");
    
    const categories = useAppSelector((state) => state.events.categories);
    const loading = useAppSelector((state) => state.events.loading);
    const count = useAppSelector((state) => state.events.eventCount);
    const next = useAppSelector((state) => state.events.eventNext);

    useEffect( () => {
        dispatch(listSubscribedEvents({filter: "live", offset: 0}));
    }, [])
    return (
    <div>
      <h1>Overview</h1>
      <Row style={{marginTop: 10}}>
            <Col md={12} xl={7} >
                <Row className="secondary align-content-center" style={{borderRadius: 20, height: 120, padding: 10}}>
                    Bienvenue ! 
                </Row>                
            </Col>
            <Col className="secondary align-content-center text-center" style={{borderRadius: 20, padding: 20, height: 120}} xl={{
                offset: 1,
                size: 4
            }}>
                <I18nLink to="/live">
                    <Button color="primary"><Trans i18nKey="backoffice.user.overview.lives">Voir les lives</Trans></Button>
                </I18nLink>
            </Col>
        </Row>
        <LiveSelector xl={6} hideCategories={true} categories={categories} states={[]} category={category} filter={filter} loading={loading} events={events} count={count} next={next} onCategoryChange={(category) => {
                setCategory(category);
                dispatch(listSubscribedEvents({filter: filter, offset: 0}))
            }} onStateChange={(filter) => {
                setFilter(filter);
                dispatch(listSubscribedEvents({filter: filter, offset: 0}))
            }} loadNext={(category, filter, offset) => {
                dispatch(listSubscribedEvents({filter: filter, offset: offset}))
            }}/>    

    </div>
  );
}