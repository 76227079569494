import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { useAppSelector, useYupValidationResolver } from "../../../hooks";
import * as yup from "yup"
import { useForm } from "react-hook-form";
import FormInput from "../../../theme/components/form-input";
import api from "../../../common/api";
import { t } from "i18next";
import { Trans } from "react-i18next";


interface CheckoutFormProps {
    successUrl: string;
    mode: string;
}

export interface CheckoutFormData {
    firstName: string;
    lastName: string;
}



export const CheckoutForm = (props: CheckoutFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<string | undefined>('');
    const user = useAppSelector(state => state.login.user);

    const CheckoutSchema = yup.object({
      firstName: yup.string().label(t("checkout.first_name")).min(2).required(),
      lastName: yup.string().label(t("checkout.last_name")).min(2).required(),
    });

    const resolver = useYupValidationResolver(CheckoutSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<CheckoutFormData>({resolver, defaultValues: {firstName: user?.firstName, lastName: user?.lastName}})
    
    const handleCheckout = async (data: CheckoutFormData) => {

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setIsLoading(true);
      const result = await api.put('/users/me/name', {firstName: data.firstName, lastName: data.lastName});
      if (result.data.error){
        setMessage(result.data.error);
        setIsLoading(false);
        return;
      }

      let error = null;
      if (props.mode === "payment") {
        const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/${props.successUrl}`,
        },
      });
      error = result.error;
    } else {
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/${props.successUrl}`,
        },
      });
    }
  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error?.type === "card_error" || error?.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
  
    }


    return <Form onSubmit={handleSubmit(handleCheckout)}>
                { message && <Alert color="danger">{message}</Alert> }
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="firstName"><Trans i18nKey={"checkout.first_name"}>Prénom</Trans></Label>
                      <FormInput 
                            type="text" 
                            placeholder={t("checkout.first_name")}
                            register={_register}
                            name="firstName"
                            invalid={errors.firstName}/>     
                      <FormFeedback>
                        { errors.firstName?.message }
                      </FormFeedback>                               
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="lastName"><Trans i18nKey={"checkout.last_name"}>Nom</Trans></Label>
                      <FormInput 
                            type="text" 
                            placeholder={t("checkout.last_name")}
                            register={_register}
                            name="lastName"
                            invalid={errors.lastName}/>                  
                      <FormFeedback>
                        { errors.lastName?.message }
                      </FormFeedback>                            
                    </FormGroup>
                  </Col>
                </Row>
                <PaymentElement />
                <div style={{textAlign: "right"}}>

                  <Button color="primary" style={{marginTop: 20}}><Trans i18nKey={"checkout.pay"}>Payer</Trans></Button>
                </div>
              </Form>
};