import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  FormFeedback,
} from "reactstrap";
import { login } from "../login-slice";
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../../logo.svg";
import * as yup from "yup"
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../theme/components/form-input";
import { relative } from "path";
import authService from "../services/auth-service";


type ForgotPasswordValues = {
  email: string,
};

const ForgotPasswordSchema = yup.object({
  email: yup.string().email().required("Email is Required"),
})

const ForgotPassword = (props : any) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  

  const resolver = useYupValidationResolver(ForgotPasswordSchema)
  const { register: _register, handleSubmit, formState: { errors } } = useForm<ForgotPasswordValues>({resolver})

  const onSubmit: SubmitHandler<ForgotPasswordValues> = (data: ForgotPasswordValues) => {
    authService.sendResetPasswordLink(data.email).then( (response) => {
        navigation("/forgot-password-success");
    }).catch( (error) => {
        
    });
  }


  
  

  return (
      <div style={{position: "relative", flex: 1, display: "flex"}}>
              <Form onSubmit={handleSubmit(onSubmit)} style={{flex: 1, alignItems: "space-between"}} className="align-middle">

                <FormGroup style={{marginTop: 25}} className="pb-2 mr-sm-2 mb-sm-0">
                  <Label for="formEmail" className="mr-sm-2">
                    Email
                  </Label>
                  <FormInput
                    type="email"
                    name="email"
                    id="formEmail"
                    placeholder="Email"
                    register={_register}
                    invalid={errors.email}
                  />

                </FormGroup>
                <Button type="submit" color={"primary"} style={{marginTop: 25}}>Retrieve my password</Button>

            </Form>

      </div>
  );
};

export default ForgotPassword;
