import { PlatformConfig } from "./PlatformConfig";
import i18n from 'i18next';

export const getLanguage = () => {
  return i18n.language ||
    (typeof window !== 'undefined' && window.localStorage && window.localStorage.i18nextLng && window.localStorage.i18nextLng.split('-')[0]) ||
    'en';
};

const platform = process.env.REACT_APP_PLATFORM ? process.env.REACT_APP_PLATFORM : "weerlive";

export function getPlaformAsset(asset: string, language: string = getLanguage()) {
    return require(`../platforms/${platform}/${asset}`)
}

export function getDefaultImage(image: string, language: string){
    try {
        return require(`../platforms/${platform}/images/en/${image}`)
    }catch(e){
        return require(`../assets/${image}`)
    }
}

export function getPlaformImage(image: string, language: string = getLanguage()) {
    try {
        return require(`../platforms/${platform}/images/${language}/${image}`)
    }catch(e){
        try {
            return require(`../platforms/${platform}/images/en/${image}`)
        }catch(e){
            return getDefaultImage(image, language);
        }
    }    
}

export function getPlatformContent(content: string){
    return import(`../platforms/${platform}/content/${content}`)
}



export function getConfig() : PlatformConfig{
    return getPlaformAsset("config").default;
}
