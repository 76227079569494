import { Input } from "reactstrap";

interface FormInputProps {
    register: any;
    name: string;
    [key: string]: any;
    onChange?: (e: any) => void;
    
}


const FormInput = (props: FormInputProps) => {
    const  { register, name, ...rest } =  props;
    const { ref, ...registerField } = register(name, { onChange: (e: any) => {props.onChange && props.onChange(e)},});
    return <Input innerRef={ref} {...registerField} {...rest} />;
  };
  
  export default FormInput;
  