import { Trans } from "react-i18next";
import { I18nLink } from "../../../theme/components/I18nLink";
import mastercard from "../images/mastercard.svg";
import idCheck from "../images/id-check.png";

export default function Footer(){
    return <>
        <div className="footer-links">
               <I18nLink to="/contact"><Trans i18nKey={"footer.contact"}>Contact</Trans></I18nLink> | <I18nLink to="/privacy-policy"><Trans i18nKey={"footer.legal"}>Mentions légales</Trans></I18nLink>     |    <I18nLink to="/terms"><Trans i18nKey={"footer.terms"}>Conditions générales de vente</Trans></I18nLink> | <I18nLink to="/declaration"><Trans i18nKey={"footer.declaration"}>Anti modern slavery declaration</Trans></I18nLink>
                <p style={{marginTop: 10}}><img style={{width: 50}} src={mastercard }/><img style={{width: 150}} src={idCheck }/><img style={{width: 50, marginLeft: 10}} src={require("../images/visa-secure.png") }/><img style={{width: 50, marginLeft: 10}} src={require("../images/visa.png") }/></p>
                <p style={{backgroundColor: "#272727"}}>The owners and operators of this Website are not the primary producer (as that term is defined in 18 USC section 2257) of any of the visual content contained in the Website. The original records required pursuant to 18 U.S.C. section 2257 and 28 C.F.R. 75 for materials contained in the website are kept by the appropriate Custodian of Records as follows: HUMFFREY, 14 res Beausoleil 92210 Saint Cloud, France</p>
        </div>    
    </>
}