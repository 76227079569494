import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './features/login/login-slice'
import appReducer from './features/app/app-slice'
import eventReducer from './features/event/event-slice'
import creatorReducer from './features/creator/creator-slice'

 const store = configureStore({
  reducer: {
    login: loginReducer,
    app: appReducer,
    events: eventReducer,
    creator: creatorReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch



export default store;