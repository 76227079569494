import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

export function LeftImageContent({image, title, description}: {image: string, title: string, description: string}) {
    const {t} = useTranslation();

    return <Row className="align-items-center row-image-content">
        <Col xl={6} xs={{size: 12, order: 2}}>
            <img src={image} className="img-fluid" />
        </Col>
        <Col xl={6} xs={{size: 12, order: 1}}>
            <h2>{title}</h2>
            <p>{description}</p>
        </Col>
    </Row>
}