import React from "react";
import { useAppSelector } from "../../../hooks";
import { listCreators, listUsers } from "../../login/login-slice";
import UserList from "../components/user-list";

export default function AdminCreators() {
  const { data, page, total, limit, loading, userName, email } = useAppSelector(
    (state) => state.login.creators  // Accède aux données des utilisateurs au lieu des créateurs
  );

  return (
    <UserList
      fetchUsers={listCreators}  // Passe l'action spécifique pour les utilisateurs
      data={data as any}
      page={page}
      total={total}
      limit={limit}
      loading={loading}
      defaultUserName={userName}
      defaultEmail={email}
      creator={true}  
    />
  );
}