import { Alert, Button, Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import FormInput from "../../../theme/components/form-input";
import { Trans } from "react-i18next";
import { useForm } from "react-hook-form";

import * as yup from "yup"
import { useYupValidationResolver } from "../../../hooks";
import { useEffect, useState } from "react";
import { CompanyDetails } from "../../login/login-slice";
import { t } from "i18next";
import api from "../../../common/api";


interface CompanyInformationsProps {
    details? : CompanyInformationsData;
    onSubmit: (data: CompanyInformationsData) => void   
}

export type CompanyInformationsData = {
    companyName: string,
    companyIdNumber: string
    vatOption: string,
    vatNumber?: string,
}
  

const CompanyInformationsValidationSchema = yup.object({
  companyName: yup.string().min(2).required(t("form.company_name_required")),
  companyIdNumber: yup.string().required(),
  vatOption: yup.number().required(t("form.vat_option_required"),),
  vatNumber: yup.string().when("vatOption", (vatOption, schema) => {
    const vatOptionNumber = vatOption[0] as number;
    if(vatOptionNumber === 0 || vatOptionNumber  === 2)
      return schema.required(t("form.vat_number_required"))

    return schema.notRequired();
    
  })
});

export default function CompanyInformations(props: CompanyInformationsProps) {
    const [showTvaForm, setShowTvaForm] = useState<boolean>(false);
    const resolver = useYupValidationResolver(CompanyInformationsValidationSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<CompanyInformationsData>({resolver, defaultValues: props.details})
    const [tvaValid, setTvaValid] = useState<boolean>(false);

    const updateTvaForm = (show: boolean) => {
        setShowTvaForm(show);
        if (show){
            setTvaValid(true);
        }

    }

    useEffect(() => {
        if (props.details){
            updateTvaForm(props.details.vatOption === "0" || props.details.vatOption === "2");
            
        }
    }, [props.details])


    const checkTvaAndSubmit = async (data: CompanyInformationsData) => {
        
        try {
            if (showTvaForm){
                const result = await api.post("/creator/validate-tva", {tva: data.vatNumber})
                if (result.data == true){
                    setTvaValid(true);

                    props.onSubmit(data);
                }else {
                    setTvaValid(false);
                } 
            }else {
                setTvaValid(true);
                props.onSubmit(data);
            }
            
        }catch(e: any){
            setTvaValid(false);

        }
        
    }

    return <><Form method="post" onSubmit={handleSubmit(checkTvaAndSubmit)} style={{marginBottom: 20}}>
    {/* <h1 style={{fontSize: 22, marginTop: 20}}><Trans i18nKey={"form.identite"}>Identité</Trans></h1> */}
        {showTvaForm && !tvaValid && <Alert color="danger"><Trans i18nKey={"form.invalid_tva"}>Numéro de TVA invalide</Trans></Alert>}
        <Row>
            <Col md={12}>
                <FormGroup>
                <Label for="formCompanyName">
                    <Trans i18nKey="form.company_name">
                        Entreprise
                    </Trans>
                </Label>
                <FormInput
                    id="formCompanyName"
                    placeholder={t("form.company_name")}
                    register={_register}
                    name="companyName" 
                    invalid={errors.companyName}/>                  
                    <FormFeedback>
                    { errors.companyName?.message }
                    </FormFeedback>                               
                </FormGroup>                             
            </Col>
            <Col md={12}>
                <FormGroup>
                <Label for="formCompanyIdNumber">
                    <Trans i18nKey="form.company_id">
                        Identifiant de l'entreprise
                    </Trans>
                </Label>
                <FormInput
                    id="formCompanyIdNumber"
                    placeholder={t("form.company_id_number")}
                    register={_register}
                    name="companyIdNumber" 
                    invalid={errors.companyIdNumber}/>                  
                    <FormFeedback>
                    { errors.companyIdNumber?.message }
                    </FormFeedback>                               
                </FormGroup>                             
            </Col>            
            <Col md={12}>
                <Label for="formCompanyIdNumber">
                    <Trans i18nKey="form.company_location_title">
                       Emplacement de votre entreprise
                    </Trans>
                </Label>
                <FormGroup className="form_vat_choices">
                <FormInput
                    id="formCompanyFranceVat"
                    placeholder="Company ID Number"
                    register={_register}
                    name="vatOption" 
                    type="radio"
                    value={0}
                    onChange={ (value) => {updateTvaForm(true)} }
                    invalid={errors.vatOption}>
                </FormInput>    
                <Label className="form_vat_label" for="formCompanyFranceVat">
                    <Trans i18nKey="form.company_location_france_with_vat">
                        En France éligible à la TVA
                    </Trans>
                </Label>              
                    <FormFeedback>
                    { errors.vatOption?.message }
                    </FormFeedback>                               
                </FormGroup>  
                <FormGroup className="form_vat_choices">
                <FormInput
                    id="formCompanyFranceNotVat"
                    register={_register}
                    name="vatOption" 
                    type="radio"
                    value={1}
                    invalid={errors.vatOption}
                    onChange={ (value) => {updateTvaForm(false)} }>
                </FormInput>           
                <Label className="form_vat_label" for="formCompanyFranceNotVat">
                    <Trans i18nKey="form.company_location_france_without_vat">
                        En France non éligible à la TVA
                    </Trans>
                </Label>       
                    <FormFeedback>
                    { errors.vatOption?.message }
                    </FormFeedback>                               
                </FormGroup>         
                <FormGroup className="form_vat_choices">
                <FormInput
                    id="formCompanyEUVat"
                    register={_register}
                    name="vatOption" 
                    type="radio"
                    value={2}
                    onChange={ (value) => {updateTvaForm(true)} }
                    invalid={errors.vatOption}>
                </FormInput>              
                <Label className="form_vat_label" for="formCompanyEUVat">
                    <Trans i18nKey="form.company_location_eu_with_vat">
                        Union Européenne éligible à la TVA
                    </Trans>
                </Label>    
                    <FormFeedback>
                    { errors.vatOption?.message }
                    </FormFeedback>                               
                </FormGroup>
                <FormGroup className="form_vat_choices">
                <FormInput
                    id="formCompanyEUNoVat"
                    register={_register}
                    name="vatOption" 
                    type="radio"
                    value={3}
                    onChange={ (value) => {updateTvaForm(false)} }
                    invalid={errors.vatOption}>
                </FormInput>           
                <Label className="form_vat_label" for="formCompanyEUNoVat">
                    <Trans i18nKey="form.company_location_eu_without_vat">
                        Union Européene non éligible à la TVA
                    </Trans>
                </Label>       
                    <FormFeedback>
                    { errors.vatOption?.message }
                    </FormFeedback>                               
                </FormGroup>
                <FormGroup>
                <FormInput
                    id="formCompanyOutsiteEU"
                    register={_register}
                    name="vatOption" 
                    type="radio"
                    value={4}
                    onChange={ (value) => {updateTvaForm(false)} }
                    invalid={errors.vatOption}>
                </FormInput>          
                <Label className="form_vat_label" for="formCompanyOutsiteEU">
                    <Trans i18nKey="form.company_location_outside_eu">
                        Hors Union Européene
                    </Trans>
                </Label>        
                    <FormFeedback>
                    { errors.companyIdNumber?.message }
                    </FormFeedback>                               
                </FormGroup>    
                { showTvaForm && <FormGroup>
                <Label for="formCompanyOutsiteEU">
                    <Trans i18nKey="form.company_tva_number">
                        Numéro de TVA
                    </Trans>
                </Label>
                <FormInput
                    id="formCompanyOutsiteEU"
                    register={_register}
                    name="vatNumber" 
                    invalid={errors.companyIdNumber}>
                </FormInput>                  
                    <FormFeedback>
                    { errors.companyIdNumber?.message }
                    </FormFeedback>                               
                </FormGroup>   }                                                                                  
                <Button color="primary" type="submit">{t("common.next")}</Button>
            </Col>                        
        </Row>
      </Form></>;
}