export enum TransfertState{
    Pending = "pending",
    Completed = "completed",
    Failed = "failed",
    Canceled = "canceled"
}

export default interface TransfertDto{
    _id: string,
    amount: number,
    state: TransfertState,
    createdAt: Date
}