import { Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import DateFilter, { DateFilterEnum } from "../components/date-filter";
import EventImage from "../../event/components/event-image";
import moment from "moment";
import { useEffect, useState } from "react";
import { listMyEvents, listMyTransferts } from "../../event/event-slice";
import { getOverview, getStats } from "../creator-slice";
import { EventState } from "../../event/dto/event.dto";
import "./stats.css"
import { Trans } from "react-i18next";
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';
import WeerliveRangePicker from "../../../theme/components/date-range-picker";

const { combine, afterToday } = DateRangePicker;

enum Order {
    REVENUEASC = "REVENUEASC",
    REVENUEDESC = "REVENUEDESC",
    VIEWS = "SUBSCRIBERSDESC"
}


export default function Stats() {

    
    const user = useAppSelector((state) => state.login.user);
    const stats = useAppSelector((state) => state.creator.stats);
    const overview = useAppSelector((state) => state.creator.overview);
    const dispatch = useAppDispatch();
    const revenues = useAppSelector(state => state.events.revenues)

    const [order, setOrder] = useState<string | undefined>(undefined);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    useEffect(() => {
        if (user){
            dispatch(listMyTransferts());
            dispatch(getOverview({startDate: undefined, endDate: undefined}));
            dispatch(getStats({startDate: undefined, endDate: undefined, order: undefined}));
        }
      }, [user]);

    return <div>
            <Row className="secondary align-content-center" style={{borderRadius: 20,  padding: 10, textAlign:"center", marginBottom: 20}}>
                    <Col md={{size: 2}} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.stream"}>Stream</Trans></h2>
                        <h3>{overview?.liveCount}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.views"}>Views</Trans></h2>
                        <h3>{overview?.views}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.revenue_live"}>Revenu Live</Trans></h2>
                        <h3>{overview?.totalLive.toFixed(2)} $</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.revenue_replay"}>Revenu Replay</Trans></h2>
                        <h3>{overview?.totalVOD.toFixed(2)} $</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.revenue_partner"}>Revenu Affiliation</Trans></h2>
                        <h3>{overview?.totalAffiliation.toFixed(2)} $</h3>
                    </Col>                    
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase',fontWeight: 'bold'}}><Trans i18nKey={"backoffice.creator.stats.revenue_total"}>Revenu Total</Trans></h2>
                        <h3 style={{fontWeight: 'bold'}}>{overview?.total.toFixed(2)} $</h3>
                    </Col>                    
                </Row>    
            
                <WeerliveRangePicker onChange={ (range) => {
                    setStartDate(range?.[0]);
                    setEndDate(range?.[1]);
                    dispatch(getOverview({startDate: range?.[0], endDate: range?.[1]}));
                    dispatch(getStats({startDate: range?.[0], endDate: range?.[1]}));
                }} />
                <span style={{marginLeft: 20, textDecoration: "underline"}}><Trans i18nKey={"backoffice.creator.stats.filter"}>Filtres</Trans></span>
                <a href="#" style={{textDecoration: order == Order.REVENUEDESC ? "underline" : undefined, marginLeft: 10, marginRight: 10}} onClick={() => {
                    if (order === Order.REVENUEDESC){
                        setOrder(undefined);
                        dispatch(getStats({startDate: startDate, endDate: endDate, order: undefined}));
                    }else {
                        setOrder(Order.REVENUEDESC);
                        dispatch(getStats({startDate: startDate, endDate: endDate, order: Order.REVENUEDESC}));
                    }
                }}>                    
                    <Trans i18nKey={"backoffice.creator.stats.revenue_plus"}>Revenues +</Trans>
                </a>
                <a href="#" style={{textDecoration: order == Order.REVENUEASC ? "underline" : undefined, marginRight: 10}} onClick={() => {
                    if (order === Order.REVENUEASC){
                        setOrder(undefined);
                        dispatch(getStats({startDate: startDate, endDate: endDate, order: undefined}));
                    }else {
                        setOrder(Order.REVENUEASC);
                        dispatch(getStats({startDate: startDate, endDate: endDate, order: Order.REVENUEASC}));
                    }
                }}>                    
                    <Trans i18nKey={"backoffice.creator.stats.revenue_moins"}>Revenue -</Trans>
                </a>  
                <a href="#" style={{textDecoration: order == Order.VIEWS ? "underline" : undefined,  marginRight: 10}} onClick={() => {
                    if (order === Order.VIEWS){
                        setOrder(undefined);
                        dispatch(getStats({startDate: startDate, endDate: endDate, order: undefined}));
                    }else {
                        setOrder(Order.VIEWS);
                        dispatch(getStats({startDate: startDate, endDate: endDate, order: Order.VIEWS}));
                    }
                }}>                    
                    <Trans i18nKey={"backoffice.creator.stats.views"}>Vues</Trans>
                </a>              
                {stats?.map((stat) => {
                return <> <Row className="secondary align-content-center stats-contain">
                <Col md={{size: 2}} xs={12} style={{display: "flex"}}>
                    <EventImage image={stat._id.event.picture} title={stat._id.event.title} color={stat._id.event.color}/>
                </Col>
                <Col md={{size: 2}} xs={12} style={{textAlign: "center", marginTop: 10}}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}>{moment(stat._id.event.date).format('DD/MM/YYYY HH:mm')}</h1>
                    <p>{stat._id.event.title.substring(0, 65)} {stat._id.event.title.length > 65 && "..." }</p>
                </Col>          
                <Col md={{size: 2}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.views"}>Inscrits</Trans></h1>
                    <p>{ stat._id.event.subscribers?.length }</p>
                </Col>                         
                <Col md={{size: 2}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.revenue_live"}>Gain live</Trans></h1>
                    <p>{ stat.totalLive.toFixed(2) } $</p>
                </Col>      
                <Col md={{size: 2}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.revenue_replay"}>Gain Replay</Trans></h1>
                    <p>{ stat.totalVOD.toFixed(2) } $</p>
                </Col>     
                <Col md={{size: 2}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.revenue_total"}>Total</Trans></h1>
                    <p>{ (stat.totalVOD +  stat.totalLive).toFixed(2)} $</p>
                </Col>                                                                
            </Row>
             </>})}
        </div>
}