import React, { useEffect, useState } from "react";
import api from '../../../common/api'
import DateRangePicker from "rsuite/esm/DateRangePicker";

const { combine, afterToday } = DateRangePicker;

export default function AdminStats() {
  const [kpis, setKpis] = useState<any>(null);  // État pour stocker les KPIs
  const [loading, setLoading] = useState<boolean>(true);
  const [dateRange, setDateRange] = useState<string>('today');  // Sélection de la période

  useEffect(() => {
    // Fonction pour récupérer les KPIs
    const fetchKpis = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/events/kpis`);
        setKpis(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des KPIs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchKpis();
  }, [dateRange]);

  if (loading) return <p>Chargement des données...</p>;

  if (!kpis) return <p>Aucune donnée disponible</p>;

  return (
    <div>
      <h2>Indicateurs de Performance</h2>

      <DateRangePicker defaultValue={[new Date(), new Date()]} shouldDisableDate={afterToday()} showOneCalendar onChange={ (range) => {
                    (async () => {
                      const response = await api.get(`/events/kpis?startDate=${range?.[0]}&endDate=${range?.[1]}`);
                      setKpis(response.data);
                    })();
                } } />

      {/* Tableau 1 : KPIs d'activité */}
      <h3>Tableau 1 : Activité</h3>
      <table style={{ width: '100%', textAlign: 'center' }}>
        <thead>
          <tr>
            <th>Nombre de Viewers</th>
            <th>Nombre de Créateurs</th>
            <th>Nombre de Lives créés</th>
            <th>Nombre de Lives terminés</th>
            <th>Replays vendus (CA TTC)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{kpis.table1.viewersCount}</td>
            <td>{kpis.table1.creatorsCount}</td>
            <td>{kpis.table1.livesCreatedCount}</td>
            <td>{kpis.table1.livesFinishedCount}</td>
            <td>{kpis.table1.replaysSoldTTC.toFixed(2)} $</td>
          </tr>
        </tbody>
      </table>

      {/* Tableau 2 : KPIs financiers */}
      <h3>Tableau 2 : Financier</h3>
      <table style={{ width: '100%', textAlign: 'center' }}>
        <thead>
          <tr>
            <th>CA TTC (Lives + Replays)</th>
            <th>CA HT (Lives + Replays)</th>
            <th>Crédit TVA</th>
            <th>Reversement Créateurs HT</th>
            <th>Reversement Payment Processor</th>
            <th>Gains d'affiliation</th>
            <th>Bénéfice</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{kpis.table2.caTotalTTC.toFixed(2)} $</td>
            <td>{kpis.table2.caHT.toFixed(2)} $</td>
            <td>{(kpis.table2.vatCredit).toFixed(2)} $</td>
            <td>{kpis.table2.reversementCreateurHT.toFixed(2)} $</td>
            <td>{kpis.table2.reversementPP.toFixed(2)} $</td>
            <td>{kpis.table2.reversementAffiliate ? kpis.table2.reversementAffiliate.toFixed(2) : '0.00'} $</td>
            <td>{kpis.table2.benef.toFixed(2)} $</td>
          </tr>
        </tbody>
      </table>
    </div>

  );
}