import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Alert,
  FormFeedback,
} from "reactstrap";
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup"
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../theme/components/form-input";
import authService from "../services/auth-service";


type ResetValues = {
  password: string,
  confirmPassword: string,
};

const ResetSchema = yup.object({
  password: yup.string().required("Required"),
  confirmPassword: yup.string().required("Required").oneOf([yup.ref('password')], 'Passwords must match'),
})

const ResetPassword = (props : any) => {
  const [error, setError] = useState<string | null>(null);
  const navigation = useNavigate();

  const dispatch = useAppDispatch();


  const resolver = useYupValidationResolver(ResetSchema)
  const { register: _register, handleSubmit, formState: { errors } } = useForm<ResetValues>({resolver})

  const {token} = useParams();

  const onSubmit: SubmitHandler<ResetValues> = (data: ResetValues) => {
    if (token){
      authService.resetPassword(token, data.password).then( (response) => {
        navigation("/reset-password-success");
      }).catch( (error) => {
          setError(error.response.data.message);
      });
    }

    
  }

  
  

  return (
      <div style={{position: "relative", flex: 1, display: "flex"}}>
              <Form onSubmit={handleSubmit(onSubmit)} style={{flex: 1, alignItems: "space-between"}} className="align-middle">
              { error && <Alert color="danger">{error}</Alert> }  

                <FormGroup style={{marginTop: 25}} className="pb-2 mr-sm-2 mb-sm-0">
                  <Label for="formPassword">
                    Mot de passe
                  </Label>
                  <FormInput
                    id="formPassword"
                    placeholder="Mot de passe"
                    type="password"
                    register={_register}
                    name="password" 
                    invalid={errors.password}/>
                    <FormFeedback>
                      { errors.password?.message }
                    </FormFeedback>                          
                </FormGroup>
                <FormGroup style={{marginTop: 25}} className="pb-2 mr-sm-2 mb-sm-0">
                  <Label for="formPassword" className="mr-sm-2">
                    Confirm Password
                  </Label>
                  <FormInput
                    type="password"
                    name="confirmPassword"
                    id="formPassword"
                    placeholder="Password"
                    register={_register}
                    invalid={errors.confirmPassword}
                  />
                  <FormFeedback>
                    { errors.confirmPassword?.message }
                  </FormFeedback>
                </FormGroup>
                <div className="bottom-container" style={{textAlign: 'center', marginTop: 20}}>
                  <Button type="submit" color="primary">
                    Reset your password
                  </Button>
                </div>
              </Form>

      </div>
  );
};

export default ResetPassword;
