import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Trans } from "react-i18next";
import { getPlaformImage } from '../../../platforms/config';
import moment from "moment";

export default function OnlyAdults() {  
    const [show, setShow] = useState(true);
    const logo = getPlaformImage("logo.svg");

    // Helper function to check if 48 hours have passed
    const has48HoursPassed = (lastTimestamp: string) => {
        const now = new Date();
        const lastVisit = new Date(lastTimestamp);
        const difference = moment(now).diff(lastVisit) / 1000 / 60 / 60; // Difference in hours
        console.log(difference);
        return difference > 48;
    };

    useEffect(() => {
        const lastVisit = localStorage.getItem("adult");
        if (lastVisit && !has48HoursPassed(lastVisit)) {
            setShow(false);
        }
    }, []);

    return (
        <>
            {show && (
                <div style={{position: "absolute", background: "black", left:0, right:0, top:0, bottom:0, zIndex:10, opacity: 0.9}}>
                    <Modal isOpen={show} centered={true}>
                        <ModalHeader style={{textAlign: "center"}}>
                            <img className="logo-desktop" style={{marginBottom: "30px"}} src={logo} alt="Platform Logo" />
                            <h3>
                                <Trans i18nKey={"popup.adult.title"}>This is an adult website</Trans>
                            </h3>
                        </ModalHeader>
                        <ModalBody style={{textAlign: "center"}}>
                            <Trans i18nKey={"popup.adult.content"}>
                                By entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you are accessing the website from and you consent to viewing sexually explicit content.
                            </Trans>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                style={{background: "#EE2F96"}}
                                className="primary"
                                onClick={() => {
                                    localStorage.setItem("adult", new Date().toISOString()); // Store the current timestamp
                                    setShow(false);
                                }}
                            >
                                <Trans i18nKey={"popup.adult.yes"}>I am 18 or older - Enter</Trans>
                            </Button>
                            <Button
                                className="btn-primary"
                                onClick={() => {
                                    window.location.href = "https://www.google.com";
                                }}
                            >
                                <Trans i18nKey={"popup.adult.no"}>I am under 18 - Exit</Trans>
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            )}
        </>
    );
}