import React, { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form"
import { useAppDispatch, useYupValidationResolver } from "../../../hooks";
import * as yup from "yup"
import authService from "../../login/services/auth-service";
import { useNavigate } from "react-router-dom";
import CreateForm, { CreateProfil } from "../components/create-form";
import { t } from "i18next";


export default function CreateViewer() {

  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>(undefined);
  const navigation = useNavigate();

  const onSubmit: SubmitHandler<CreateProfil> = (data) => {
      authService.register(data.country, data.userName, data.email, data.password).then( (response) => {
        if (response.status === 200 || response.status === 201) {
          navigation("/validation");
        }else {
          setError(response.data.message);
        }
      }).catch( (error) => {
        console.log(error);
        setError(error.response.data.message);
      });

  }

  return <>
      <CreateForm onSubmit={onSubmit} error={error} submitLabel={t("form.confirm")} />
  </>;
}