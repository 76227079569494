import { useTranslation } from "react-i18next";
import { Page } from "../../../theme/templates/page";
import React, { Suspense } from "react";
import { getPlatformContent } from "../../../platforms/config";


export default function Legal() {
    const { t } = useTranslation(); 
    const Legal = React.lazy( () => getPlatformContent('legal'))

    return ( <Page>
            <Suspense>
                <Legal />
            </Suspense>
        </Page>
    )
}