import { createContext, useState } from 'react';
import {  Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';


export interface LoadingState {
    isLoading: boolean;
};


const initialState : LoadingState = {
  isLoading: false
};


const LoadingContext = createContext({
  ...initialState,
  show: (isLoading: boolean)  => {
    
  },
});

export const LoadingProvider = ({ children } : { children : React.ReactElement}) => {
    const [loading, setLoading] = useState<boolean>(false);
    
    const show = (loading: boolean) => {
      setLoading(loading);
    };

  return (
    <LoadingContext.Provider
      value={{
        isLoading: loading,
        show
      }}
    >   
        <Modal isOpen={loading} centered={true} style={{ border: 0, borderRadius: 50, width: 100}}>

            <ModalBody style={{textAlign: "center"}}>
                <Spinner color="light">
                 Loading...
                </Spinner>
            </ModalBody>
        </Modal> 
      
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
