import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import useModal from "../../../theme/hooks/use-modal";
import React from "react";
import { t } from "i18next";
import { I18nLink } from "../../../theme/components/I18nLink";

export interface CreateLiveLinkProps{
    children: React.ReactNode;
}

export function CreateLiveLink(props: CreateLiveLinkProps) {
    const user = useAppSelector((state) => state.login.user);
    const navigate = useNavigate();
    const modal = useModal();

    return <I18nLink to={user?.profile?.companyDetails === undefined ? "" : "/creator/create-event"} onClick={ () => {
        if (user?.profile?.companyDetails === undefined){
            modal.show(t("backoffice.creator.profil_incomplete_modal.title"), <>{t("backoffice.creator.profil_incomplete_modal.message")}</>, [{label: t("backoffice.creator.profil_incomplete_modal.valid") , onClick: () => {
                navigate("/creator/settings");
            }}, {label: t("backoffice.creator.profil_incomplete_modal.cancel"), onClick: () => {
                
            }}]) 
        }
    }}>
        {props.children}
    </I18nLink>
}