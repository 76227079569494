import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import './i18n';
import { getPlaformAsset } from './platforms/config';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const customPlatformCss =  getPlaformAsset('override.css');


root.render(
  //<React.StrictMode>
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
        <link rel="stylesheet" href={customPlatformCss} />
    </Provider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
