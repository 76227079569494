import { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import api from "../../../common/api";
import { Link } from "react-router-dom";
import BASE_URL from "../../../common/urls";
import { validate } from "uuid";
import useModal from "../../../theme/hooks/use-modal";

export default function AdminPayments() {
  const [transferts, setTransferts] = useState<any[]>([]);
  
  const updateTransferts = () => {
    api.get('/transfert/pending').then( (response) => {
        setTransferts(response.data);
    });
  }

  useEffect( () => {

    updateTransferts();
    
  }, []);

  const validateSepa = () => {
    api.post('/transfert/validate-sepa').then( () => {
        api.get('/transfert/pending').then( (response) => {
            setTransferts(response.data);
        });
    }
  )};

  const validateOthers = () => {
    api.post(`/transfert/validate-others`).then( () => {
        api.get('/transfert/pending').then( (response) => {
            setTransferts(response.data);
        });
    }
  )};

  const modal = useModal();

  return <div>
    <Table dark={true} >
        <thead>
            <tr>
                <th>#</th>
                <th>Utilisateur</th>
                <th>Montant</th>
                <th>Montant TVA</th>
                <th>Statut</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {transferts.map( (transfert, index) => {
                return <tr key={index}>
                    <td>{transfert._id}</td>
                    <td>{transfert.user.userName}</td>
                    <td>{transfert.amount}</td>
                    <td>{transfert.amountTVA}</td>
                    <td>{transfert.state}</td>
                    <td><a href="#" onClick={ () => {
                            modal.show("Cancel this transfert ?", <div></div>,[
                                {
                                    label: "Cancel",
                                    onClick: () => {
                                        api.delete(`/transfert/${transfert._id}`).then( () => {
                                            updateTransferts();
                                        });
                                    }
                                },
                                {
                                    label: "Close",
                                    onClick: () => {
                    
                                    }
                                }]);
                            
                    }}>Cancel</a></td>
                </tr>
            })}
        </tbody>
    </Table>
    <Link to={BASE_URL+"/transfert/csv"}><Button color="primary">Download CSV</Button></Link>
    <Link to={BASE_URL+"/transfert/csv-others"}><Button color="primary">Download CSV Others</Button></Link>

    <td><Button color="primary" onClick={ () => {
                        validateSepa();
                    }}>Validate Sepa
            </Button>
            <Button color="primary" onClick={ () => {
                        validateOthers();
            }}>Validate Others</Button></td>

  </div>;
}