import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";

export function LandingCard({icon, picto, step, title, description}: {icon: string, picto: string, step: number, title: React.ReactElement, description: string}) {
    return <Col className="landing-card">
        <div className="landing-card-step">
            {step}
        </div>
        <div className="landing-card-icon">
            <img className="picto-step" src={picto} />
            <img src={icon} />
        </div>
        <h2>{title}</h2>
        <p>{description}</p>
    </Col>
}
