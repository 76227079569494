import { FormGroup, Input } from "reactstrap";
import Close from "../../../theme/components/close";
import useModal from "../../../theme/hooks/use-modal";
import { ConnectedUser } from "../dto/connected-user.dto";
import { useContext } from "react";
import socketContext from "../context/socket-context";

interface VideoProps{
    className: string;
    participant: any;
    streamsToDisplay: any[];
    username: string;
    participantSize: number;
    me: any;
    users: ConnectedUser[];
}

const Video = (props: VideoProps) => {
    const modal = useModal();
    const socket = useContext(socketContext);

    return (
      // Container for the video with relative positioning
        <div className="relative">
          {/* Video element with key, muted attribute, and other necessary video properties */}
          <video
            key={props.participant?.id} // Unique key for the video element
            muted={props.participant?.isLocal} // Mute the video if it is local
            controls={false} // Disable video controls
            autoPlay // Enable autoplay for the video
            playsInline // Enable inline playback for the video
            className={props.className} // CSS class for the video element
            ref={(ref) => {
              // Function to set the video stream
              if (ref) {
                ref.srcObject = new MediaStream(); // Initialize a new MediaStream
                props.streamsToDisplay?.forEach((stream) =>
                  (ref.srcObject as any).addTrack(stream.mediaStreamTrack)
                );
              }
            }}
          />
          {/* Overlay container for the username */}
          <div className="overlay-video-content">
                            <div style={{position: "absolute", top: 10, left: 10, textAlign: "center", background: "rgba(19,16,21, 0.8)", fontSize: 12, borderRadius: 20, paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, marginRight: 10}}>
                                {props.username}  
                            </div>
                        { props.me.role == 2 && props.username != props.me.userName && <>
                                <div style={{position: "absolute", right: 50, top : 10, backgroundColor: "rgba(19,16,21, 0.8)", borderRadius: 20, paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, marginRight: 10, alignContent: "center"}}>
                                    <FormGroup switch style={{alignItems: "center", justifyItems: "center", justifyContent: "center", flexDirection: "row"}}>
                                        <label htmlFor="show-on-stream">Show on stream</label>
                                        <Input checked={/*socketUser ? socketUser.visible : false*/ true } type="switch" id="show-on-stream" onChange={ (event) => {
                                            //socket.emit("user-visible", {socketID: socketUser?.socketID})
                                        }} />
                                    </FormGroup>
                                </div>
                                      
                        <div style={{position: "absolute", top:5, right:5}}>
                        <Close onClick={ () => {
                            const user = props.users.find( (user) => user.userName == props.username  )
                            modal.show("Étes-vous sur de vouloir retirer l'utilisateur du live ?", <>Étes-vous sur de vouloir retirer l'utilisateur du live ?</>, [{
                                    label: "Oui",
                                    onClick : () => {
                                        if (user){
                                            socket.emit("user-invite", {socketID: user.socketID});
                                        }
                                    }
                                },
                                {
                                    label: "Non",
                                    onClick : () => {

                                    }
                                }
                            ]);
                              
                        } }/>
                        </div>
                      </> }
                    </div>
        
        </div>
    );
  };
  
  export default Video; // Export the Video component
  