import { useTranslation } from "react-i18next";
import { Page } from "../../../theme/templates/page";
import React, { Suspense } from "react";
import { getPlatformContent } from "../../../platforms/config";


export default function Faq() {
    const Legal = React.lazy( () => getPlatformContent('faq'))

    return ( <Page>
            <Suspense>
                <Legal />
            </Suspense>
        </Page>
    )
}