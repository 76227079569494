import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import useModal from "./hooks/use-modal";
import useAlert from "./hooks/use-alert";
import Alert from "./components/alert";
import { AlertType } from "./contexts/alert-context";
import { Page } from "./templates/page";
import { EventCard } from "../features/event/components/event-card";
import { EventPaymentType, EventState } from "../features/event/dto/event.dto";
import { PaymentType } from "../features/event/dto/create-event.dto";
import Preview from "./components/preview";
import { Dashboard } from "./templates/dashboard";
import CreatorHeader from "../features/creator/components/creator-header";

export default function Demo() {

    const modal = useModal();
    const alert = useAlert();
    
    return <Page>
        <Alert/>
        <h1>Event Cards</h1>
        <Row xs={2} md={2} xl={3}>

        <Col><EventCard event={{
            _id: "1",
            date: new Date(),
            state: EventState.Live,
            priceWithVAT: 10,
            title: 'Event 1 - Event 1 Event 1 Event 1 Event 1 Event 1 Event 1 Event 1 Event 1 Event 1',
            description: 'Description of event 1',
            category: 'Category 1',
            price: 10,
            paymentType: EventPaymentType.Event,
            isPublic: true,
            priceVOD: 10,
            chatEnabled: true,
            vodURL: '',
            isOwner: true,
            owner: {
                _id: '1',
                userName: 'Owner 1',
                email: '',
                picture: '',
                profile: {
                    description: 'Description of owner 1',

                }
            }
            }}/></Col>
            <Col><EventCard event={{
            _id: "1",
            date: new Date(),
            state: EventState.Live,
            priceWithVAT: 10,
            title: 'Event 1 - Event 1 Event 1 Event 1 Event 1 Event 1 Event 1 Event 1 Event 1 Event 1',
            description: 'Description of event 1',
            category: 'Category 1',
            price: 10,
            paymentType: EventPaymentType.Event,
            isPublic: true,
            priceVOD: 10,
            chatEnabled: true,
            vodURL: '',
            owner: {
                _id: '1',
                userName: 'Owner 1',
                email: '',
                picture: '',
                profile: {
                    description: 'Description of owner 1',

                }
            }
            }}/>
        </Col>
            <Col>
            <EventCard event={{
                _id: "1",
                priceWithVAT: 10,
                date: new Date(),
                state: EventState.Done,
                title: 'Event 1',
                description: 'Description of event 1',
                category: 'Category 1',
                price: 10,
                paymentType: EventPaymentType.Event,
                isPublic: true,
                priceVOD: 10,
                chatEnabled: true,
                vodURL: '',
                owner: {
                    _id: '1',
                    userName: 'mon-username-est-super-long-est-ce-que-ca-fonctionne-comme-camon-username-est-super-long-est-ce-que-ca-fonctionne-comme-ca',
                    email: '',
                    picture: require('../assets/profile.webp'),
                    profile: {
                        description: 'Description of owner 1',

                    }
                }
            }}/>
        </Col>
            <Col>
            <EventCard event={{
                _id: "1",
                date: new Date(),
                state: EventState.Draft,
                priceWithVAT: 10,
                title: 'Event 1',
                description: 'Description of event 1',
                category: 'Category 1',
                price: 10,
                paymentType: EventPaymentType.Event,
                isPublic: true,
                priceVOD: 10,
                chatEnabled: true,
                vodURL: '',
                owner: {
                    _id: '1',
                    userName: 'Owner 1',
                    email: '',
                    picture: '',
                    profile: {
                        description: 'Description of owner 1',

                    }
                }
            }}/>
            </Col>

        </Row>

        <h1>Buttons</h1>
        
        <Button color="primary" onClick={ () => {
            modal.show('Hello World', <>'This is a modal message'</>, [{label: 'Close', onClick: () => {
                alert.setAlert('Modal closed', AlertType.ERROR)
            }}])
            }}>Main button</Button>

        <UncontrolledDropdown>
        <DropdownToggle
            caret
            color="dark"
        >
            dd
        </DropdownToggle>
        <DropdownMenu dark>
            <DropdownItem >
            Header
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem >
            Header
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
            Bar Action
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
            Quo Action
            </DropdownItem>
        </DropdownMenu>
        </UncontrolledDropdown>
        
        <CreatorHeader creator={{
            userName: 'Owner 1',
            color: 'red',
            picture: '',            
            description: 'Description of owner 1',
            tiktok: 'tiktok',
            instagram: 'instagram',
            youtube: 'youtube',
            lastLive: new Date(),

        }}/>
        
        <CreatorHeader creator={{
            userName: 'Owner 1',
            color: 'red',
            picture: '',            
            description: 'Description of owner 1',
            tiktok: 'tiktok',
            instagram: 'instagram',
            youtube: 'youtube',
            lastLive: new Date(),
            isLive: true

        }}/>

                
<CreatorHeader creator={{
            userName: 'Owner 1',
            color: 'red',
            picture: require('../assets/profile.webp'),    
            description: 'Description of owner 1',
            tiktok: 'tiktok',
            instagram: 'instagram',
            youtube: 'youtube',
            lastLive: new Date(),

        }}/>
        
        <CreatorHeader creator={{
            userName: 'Owner 1',
            color: 'red',
            picture: require('../assets/profile.webp'), 
            description: 'Description of owner 1',
            tiktok: 'tiktok',
            instagram: 'instagram',
            youtube: 'youtube',
            lastLive: new Date(),
            isLive: true
        }}/>
        <Dashboard  title="test">
        
        <></>
    </Dashboard>

    <div style={{borderRadius: 200, display: "flex", width: 200, height: 200, justifyContent: "center", alignItems: "center", backgroundImage: "url(https://png.pngtree.com/png-vector/20191110/ourmid/pngtree-avatar-icon-profile-icon-member-login-vector-isolated-png-image_1978396.jpg)",  backgroundRepeat: "no-repeat", backgroundSize: "cover"}} >
        <h1>L</h1>
    </div>

    <div style={{borderRadius: 200, display: "flex", width: 200, height: 200, justifyContent: "center", alignItems: "center", backgroundColor: "red",  backgroundRepeat: "no-repeat", backgroundSize: "cover"}} >
        <h1>L</h1>
    </div>


    </Page>;
}
