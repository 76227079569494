import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { activate } from "../login-slice";

export default function Activate() {
    const navigation = useNavigate();
    let { id } = useParams();
    let activated = useAppSelector( (state) => state.login.activated );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (id){
          dispatch(activate({id}));
        }
    }, [id]);

    useEffect(() => {
      if (activated !== undefined){
        navigation("/login");
      }
  }, [activated]);
    return <></>
  }