import { useEffect, useState } from "react";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import api from "../../../common/api";
import { EventDTO } from "../dto/event.dto";
import EventHeader from "../components/event-header";
import CreatorImage from "../../creator/components/creator-image";
import "./play.css";

export default function VOD() {
    const {id} = useParams();
    const [event, setEvent] = useState<EventDTO | undefined>();
    const navigate = useNavigate();

    useEffect(() => {
      api.get(`/events/${id}/vod`).then((response) => {
        setEvent(response.data);
      }).catch((error) => {
        if (error.response.status === 404){
          navigate('/');
        }else if (error.response.status === 401 || error.response.status === 403){
          navigate(`/event/${id}/checkout`);
        }
      });
    }, [id]);

    return (
      <div>
            {event && <>
              <video style={{width: "100%"}} controls src={!event.vodURL.startsWith("https://") ? "https://" + event.vodURL : "" + event.vodURL} /> 
              <div style={{display: "flex", flexDirection: "row", marginBottom: 50, marginLeft: 50, marginTop: 30}}>
                <div style={{marginRight: 10, display: "flex", alignItems:"flex-start"}}>
                    <CreatorImage userName={event.owner.userName} style={{maxHeight: 50}} image={event.owner.picture} color={event.owner.color}/>
                </div>
                <div>
                    <h2 style={{color: "#8A8A8A", fontSize: 14}}>{event.owner.userName}</h2>
                    <h1 style={{color: "#DFDFDF", fontSize: 16}}>{event.title}</h1>
                    <p style={{color: "#8A8A8A", fontSize: 16}}>{event.description}</p>
                </div>
            </div>
            </>}
      </div>
    );
}