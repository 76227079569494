import { number } from "yup";
import BankAccount, { AustraliaBankAccount, BankAccountData, CanadaBankAccount, NonSepaBankAccount, NonSepaBankAccountData, SepaBankAccount, USBankAccount } from "./bank-account";
import BankAccountHolder, { BankAccountHolderData } from "./bank-acount-holder";
import CompanyInformations, { CompanyInformationsData } from "./company-informations";
import { useEffect, useState } from "react";
import api from "../../../common/api";
import { CompanyDetails, setUser, setUserData } from "../../login/login-slice";
import { useAppDispatch } from "../../../hooks";


export interface BankWizardProps {
    details? : CompanyDetails;
    onClose: () => void;
}

export default function BankWizard(props: BankWizardProps) {
    const [step, setStep] = useState<number>(0);
    const [companyInformations, setCompanyInformations] = useState<CompanyInformationsData>();
    const [bankAccountHolder, setBankAccountHolder] = useState<BankAccountHolderData>();
    const dispatch = useAppDispatch();

    const companyInformationsSubmit = (data: CompanyInformationsData) => {
        console.log(data);
        setCompanyInformations(data);
        setStep(1);
    }

    const bankAccountHolderSubmit = (data: BankAccountHolderData) => {
        console.log(data);
        setBankAccountHolder(data);
        setStep(2);
    }

    const bankAccountSubmit = (data: BankAccountData) => {
        
        setStep(3);
        console.log(data);
        api.put('/creator/me/bank-account', {
                ...companyInformations,
                ...bankAccountHolder,
                ...data
        }).then( (response) => {
            dispatch(setUserData(response.data))
            props.onClose();
        });
    }


    return <div>
        { step == 0 && <CompanyInformations onSubmit={companyInformationsSubmit} details={props.details ? { companyIdNumber: props.details.companyIdNumber, companyName: props.details.companyName, vatNumber: props.details.vatNumber, vatOption: props.details.vatOption.toString()}: undefined} /> }
        { step == 1 && <BankAccountHolder onPrevious={ () => {
            setStep(0)
        }} onSubmit={bankAccountHolderSubmit}  details={props.details ? {address: props.details.address, city: props.details.city, country: props.details.country, postCode: props.details.postCode, owner: props.details.owner } : undefined}/>}
        { step == 2 && bankAccountHolder && <BankAccount country={bankAccountHolder?.country} details={props.details ? {accountNumber: props.details.accountNumber, bsb: props.details.bsb, institutionNumber: props.details.institutionNumber, swift: props.details.swift, transitNumber: props.details.transitNumber} : undefined} onSubmit={bankAccountSubmit} onPrevious={ () => {
            setStep(1)
        }} />  }
    </div>;
    
}