import { Link, useLocation, useParams } from "react-router-dom";

interface I18nLinkProps {
    to: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    preventScrollReset?: boolean;
    target?: string;
    onClick?: () => void;
}

export const I18nLink = (props: I18nLinkProps) => {
  const { lang } = useParams();
  return (
    <Link to={`${props.to.startsWith('/') && lang ? '/'+lang : ''}${props.to}`} style={props.style} className={props.className} preventScrollReset={props.preventScrollReset} target={props.target} onClick={props.onClick}>
      {props.children}
    </Link>
  );
};
