import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col } from "reactstrap";

export default function Validation() {
    const navigation = useNavigate();
    let { id } = useParams();

    return <>
        <p>We have sent you a verification e-mail.</p>
        <p>Please verify your account via the link in the e-mail and complete registration.</p>
        <p>If you don’t receive an email from us, please check your spam folder or contact customer support..</p>
        <p>Weerlive team</p>
        <Col style={{marginTop: 20}}>
            <Button color="primary" onClick={() => navigation("/")}>Back to homepage</Button>
        </Col>
    </>
  }