
import React from "react";

import { Dashboard } from "./templates/dashboard";

export default function DemoDashboard(){
    return <Dashboard  title="test">
        
        <></>
    </Dashboard>
}