import { Button, Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import FormInput from "../../../theme/components/form-input";
import { Trans } from "react-i18next";
import { useForm } from "react-hook-form";

import * as yup from "yup"
import { useYupValidationResolver } from "../../../hooks";
import { t } from "i18next";

const SepaBankAccountSchema = yup.object({
    accountNumber: yup.string().min(2).required(t("form.validation.ibanRequired")),
    swift: yup.string().min(2).required(t("form.validation.bicRequired")),
});
  
export interface SepaBankAccountData {
    accountNumber: string,
    swift: string,
}

const NonSepaBankAccountSchema = yup.object({
    accountNumber: yup.string().min(2).required(t("form.validation.ibanRequired")),
    swift: yup.string().min(2).required(t("form.validation.bicRequired")),
});

export interface NonSepaBankAccountData {
    accountNumber: string;
    swift: string;
}

const USBankAccountSchema = yup.object({
    accountNumber: yup.string().min(2).required(t("form.validation.ibanRequired")),
    swift: yup.string().min(2).required(t("form.validation.bicRequired")),
});


export interface USBankAccountData {
    accountNumber: string,
    swift: string,
}

const CanadaBankAccountSchema = yup.object({
    transitNumber: yup.string().min(2).required(t("form.validation.ibanRequired")),
    institutionNumber: yup.string().min(2).required("InstitutionNumber is required"),
    accountNumber: yup.string().min(2).required("accountNumber is required"),
    swift: yup.string().min(2).required(t("form.validation.bicRequired")),
});

export interface CanadaBankAccountData {
    transitNumber: string,
    institutionNumber: string,
    swift: string,
    accountNumber: string,
}

const AustraliaBankAccountSchema = yup.object({
    accountNumber: yup.string().min(2).required(t("form.validation.ibanRequired")),
    swift: yup.string().min(2).required(t("form.validation.swiftRequired")),
    bsb: yup.string().min(2).required(t("form.validation.bsbRequired"))
  
});


export interface AustraliaBankAccountData {
    bsb: string,
    accountNumber: string,
    swift: string,
}

export type BankAccountData = SepaBankAccountData | NonSepaBankAccountData | USBankAccountData | CanadaBankAccountData | AustraliaBankAccountData;


  
interface BankAccountProps {
    details? : BankAccountData;
    onSubmit: (data: BankAccountData) => void;
    country: string;
    onPrevious: () => void
}

const sepaCountries = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GB", "GR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK", "CH"];

const BankAccountValidationSchema = yup.object({
  iban: yup.string().min(2).required(t("form.validation.ibanRequired")),
  bic: yup.string().min(2).required(t("form.validation.bicRequired")),
  vatOption: yup.number().required(t("form.validation.categoryRequired")),
  vatNumber: yup.string().min(2).required(t("form.validation.countryRequired")),
});



export function SepaBankAccount(props: BankAccountProps) {
    const resolver = useYupValidationResolver(SepaBankAccountSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<SepaBankAccountData>({resolver, defaultValues: props.details})
    
    return <Form method="post" onSubmit={handleSubmit(props.onSubmit)} style={{marginBottom: 20}}>
        <Row>
            <Col md={12}>
                <FormGroup>
                    <Label for="formAccountNumber">
                        <Trans i18nKey="backoffice.creator.bank_account.iban">IBAN</Trans>
                    </Label>    
                    <FormInput
                        id="formAccountNumber"
                        placeholder={t("backoffice.creator.bank_account.iban")}
                        register={_register}
                        name="accountNumber" 
                        invalid={errors.accountNumber}/>                  
                        <FormFeedback>
                        { errors.accountNumber?.message }
                        </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="formSwift">
                        <Trans i18nKey="backoffice.creator.bank_account.swiftBic">
                            Swift / BIC
                        </Trans>
                    </Label>    
                    <FormInput
                        id="formSwift"
                        placeholder={t("backoffice.creator.bank_account.swiftBic")}
                        register={_register}
                        name="swift" 
                        invalid={errors.swift}/>                  
                        <FormFeedback>
                        { errors.swift?.message }
                        </FormFeedback>
                </FormGroup>                
            </Col>
        </Row>
        <Button onClick={props.onPrevious}><Trans i18nKey="form.previous">Précédent</Trans></Button>
        <Button type="submit" color="primary"><Trans i18nKey="form.valid">Valider</Trans></Button>
    </Form>
}




export function NonSepaBankAccount(props: BankAccountProps) {
    const resolver = useYupValidationResolver(NonSepaBankAccountSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<NonSepaBankAccountData>({resolver, defaultValues: props.details})
    
    return <Form method="post" onSubmit={handleSubmit(props.onSubmit)} style={{marginBottom: 20}}>
        <Row>
            <Col md={12}>
                <FormGroup>
                    <Label for="formAccountNumber">
                        <Trans i18nKey="backoffice.creator.bank_account.account_number_or_iban">Account Number or IBAN</Trans>
                    </Label>    
                    <FormInput
                        id="formAccountNumber"
                        placeholder={t("backoffice.creator.bank_account.account_number_or_iban")}
                        register={_register}
                        name="accountNumber" 
                        invalid={errors.accountNumber}/>                  
                        <FormFeedback>
                        { errors.accountNumber?.message }
                        </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="formSwift">
                    <Trans i18nKey="backoffice.creator.bank_account.swiftBic">Swift / BIC</Trans>
                    </Label>    
                    <FormInput
                        id="formSwift"
                        placeholder={t("backoffice.creator.bank_account.swiftBic")}
                        register={_register}
                        name="swift" 
                        invalid={errors.swift}/>                  
                        <FormFeedback>
                        { errors.swift?.message }
                        </FormFeedback>
                </FormGroup>                
            </Col>
        </Row>
        <Button onClick={props.onPrevious} style={{marginRight: 10}} color="primary"><Trans i18nKey="form.previous"></Trans></Button>
        <Button type="submit" color="primary"><Trans i18nKey="form.valid"></Trans></Button>
    </Form>
    
}


export function USBankAccount(props: BankAccountProps) {
    const resolver = useYupValidationResolver(USBankAccountSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<USBankAccountData>({resolver, defaultValues: props.details})

    return <Form method="post" onSubmit={handleSubmit(props.onSubmit)} style={{marginBottom: 20}}>
        <Row>
            <Col md={12}>
                <FormGroup>
                    <Label for="formAccountNumber">
                        <Trans i18nKey="backoffice.creator.bank_account.acct">ACCT or A/C code ( account number)</Trans>
                    </Label>    
                    <FormInput
                        id="formAccountNumber"
                        placeholder={t("backoffice.creator.bank_account.acct")}
                        register={_register}
                        name="accountNumber" 
                        invalid={errors.accountNumber}/>                  
                        <FormFeedback>
                        { errors.accountNumber?.message }
                        </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="formSwift">
                        {t("backoffice.creator.bank_account.swiftBic")}
                    </Label>    
                    <FormInput
                        id="formSwift"
                        placeholder={t("backoffice.creator.bank_account.swiftBic")}
                        register={_register}
                        name="swift" 
                        invalid={errors.swift}/>                  
                        <FormFeedback>
                        { errors.swift?.message }
                        </FormFeedback>
                </FormGroup>                
            </Col>
        </Row>
        <Button onClick={props.onPrevious} style={{marginRight: 10}} color="primary"><Trans i18nKey="form.previous"/></Button>

        <Button type="submit" color="primary"><Trans i18nKey="form.valid" /></Button>
    </Form>

}

export function CanadaBankAccount(props: BankAccountProps) {
    const resolver = useYupValidationResolver(CanadaBankAccountSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<CanadaBankAccountData>({resolver, defaultValues: props.details})
    
    return <Form method="post" onSubmit={handleSubmit(props.onSubmit)} style={{marginBottom: 20}}>
        <Row>
            <Col md={12}>
                <FormGroup>
                    <Label for="formInstitutionNumber">
                        <Trans i18nKey="backoffice.creator.bank_account.bib">Banking institution number</Trans>
                    </Label>    
                    <FormInput
                        id="formInstitutionNumber"
                        placeholder={t("backoffice.creator.bank_account.bib")}
                        register={_register}
                        name="institutionNumber" 
                        invalid={errors.institutionNumber}/>                  
                        <FormFeedback>
                        { errors.institutionNumber?.message }
                        </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="formTransitNumber">
                        <Trans i18nKey="backoffice.creator.bank_account.bank_transit_number">Banking transit number</Trans>
                    </Label>    
                    <FormInput
                        id="formTransitNumber"
                        placeholder={t("backoffice.creator.bank_account.bank_transit_number")}
                        register={_register}
                        name="transitNumber" 
                        invalid={errors.transitNumber}/>                  
                        <FormFeedback>
                        { errors.transitNumber?.message }
                        </FormFeedback>
                </FormGroup>      
                <FormGroup>
                    <Label for="formAccountNumber">
                        <Trans i18nKey="backoffice.creator.bank_account.account_number">Account Number</Trans>
                    </Label>    
                    <FormInput
                        id="formAccountNumber"
                        placeholder={t("backoffice.creator.bank_account.account_number")}
                        register={_register}
                        name="accountNumber" 
                        invalid={errors.accountNumber}/>                  
                        <FormFeedback>
                        { errors.accountNumber?.message }
                        </FormFeedback>
                </FormGroup>            
                <FormGroup>
                    <Label for="formSwift">
                        <Trans i18nKey="backoffice.creator.bank_account.swiftBic">
                            Swift / BIC Code
                        </Trans>
                    </Label>    
                    <FormInput
                        id="formSwift"
                        placeholder={t("backoffice.creator.bank_account.swiftBic")}
                        register={_register}
                        name="swift" 
                        invalid={errors.swift}/>                  
                        <FormFeedback>
                        { errors.swift?.message }
                        </FormFeedback>
                </FormGroup>                                                
            </Col>
        </Row>
        <Button onClick={props.onPrevious} style={{marginRight: 10}} color="primary">Précédent</Button>

        <Button type="submit" color="primary">
            <Trans i18nKey={"form.valid"}>Valider</Trans>
        </Button>
    </Form>
}


export function AustraliaBankAccount(props: BankAccountProps) {
    const resolver = useYupValidationResolver(AustraliaBankAccountSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<AustraliaBankAccountData>({resolver})
    
    return <Form method="post" onSubmit={handleSubmit(props.onSubmit)} style={{marginBottom: 20}}>
        <Row>
            <Col md={12}>
                <FormGroup>
                    <Label for="formBSB">                        
                        <Trans i18nKey="backoffice.creator.bank_account.bsb">
                            BSB
                        </Trans>
                    </Label>    
                    <FormInput
                        id="formBSB"
                        placeholder={t("backoffice.creator.bank_account.bsb")}
                        register={_register}
                        name="bsb" 
                        invalid={errors.bsb}/>                  
                        <FormFeedback>
                        { errors.bsb?.message }
                        </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="formAccountNumber">
                        {t("backoffice.creator.bank_account.accountNumber")}
                    </Label>    
                    <FormInput
                        id="formAccountNumber"
                        placeholder={t("backoffice.creator.bank_account.accountNumber")}
                        register={_register}
                        name="accountNumber" 
                        invalid={errors.accountNumber}/>                  
                        <FormFeedback>
                        { errors.accountNumber?.message }
                        </FormFeedback>
                </FormGroup> 
                <FormGroup>
                    <Label for="formSwift">
                        <Trans i18nKey="backoffice.creator.bank_account.swiftBic">
                            Swift / BIC Code
                        </Trans>
                    </Label>    
                    <FormInput
                        id="formSwift"
                        placeholder={t("backoffice.creator.bank_account.swiftBic")}
                        register={_register}
                        name="swift" 
                        invalid={errors.swift}/>                  
                        <FormFeedback>
                        { errors.swift?.message }
                        </FormFeedback>
                </FormGroup>                                
            </Col>
        </Row>
        <Button onClick={props.onPrevious} style={{marginRight: 10}} color="primary">Précédent</Button>
        <Button type="submit" color="primary">Valider</Button>
    </Form>
}



export default function BankAccount(props: BankAccountProps) {
    const resolver = useYupValidationResolver(BankAccountValidationSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<BankAccountData>({resolver})
    
    if (sepaCountries.includes(props.country)){
        return <SepaBankAccount {...props} />
    }else if (props.country === "US"){
        return <USBankAccount {...props} />
    } else if (props.country === "CA"){
        return <CanadaBankAccount {...props} />
    } else if (props.country === "AU"){
        return <AustraliaBankAccount {...props} />
    } else {
        return <NonSepaBankAccount {...props} />
    }
}