import React, { useEffect, useState } from "react";
import { Col, FormGroup, Row, Label, Input, Button, Form, FormFeedback, ModalFooter, ModalBody, ModalHeader, Modal, Alert, Spinner } from "reactstrap";
import FormInput from "../../../theme/components/form-input";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import * as yup from "yup"
import { cleanMessage, deleteUser, update, updateCreatorSettings, updatePassword } from "../../login/login-slice";
import { Trans } from "react-i18next";
import BankWizard from "../components/bank-wizard";
import { t } from "i18next";
import api  from "../../../common/api";
import { IDValidation } from "../components/id-validation";
import { DeleteAccountModal } from "../../viewer/containers/delete-account";




type EditSettingsFormValues = {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    bankAccount: string,
}
  

const EditSettingsValidationSchema = yup.object({
  firstName: yup.string().min(2).required("First Name is Required"),
  lastName: yup.string().min(2).required("Lastname is required"),
  phoneNumber: yup.string().notRequired(),
  bankAccount: yup.string().notRequired(),
  email: yup.string().email().notRequired(),
})


export type UpdatePasswordValues = {
  password: string,
  confirmPassword: string,
}

const UpdatePasswordSchema = yup.object({
  password: yup.string().min(8).required("Password required"),
  confirmPassword: yup.string().min(8).required("Required").oneOf([yup.ref('password')], 'Passwords must match'),
})




export default function Settings(){
    const resolver = useYupValidationResolver(EditSettingsValidationSchema)
    const user = useAppSelector((state) => state.login.user);
    const { register: _register, handleSubmit, formState: { errors } } = useForm<EditSettingsFormValues>({resolver, defaultValues: {firstName: user?.firstName, lastName: user?.lastName, bankAccount: user?.profile?.bankAccount, email: user?.email, phone: user?.profile?.phone}})
    const dispatch = useAppDispatch();
    const onSubmit: SubmitHandler<EditSettingsFormValues> = (data) => {
        dispatch(updateCreatorSettings({firstName : data.firstName, lastName: data.lastName, bankAccount: data.bankAccount, email: data.email, phone: data.phone}));
    }    




    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [showBankAccountModal, setShowBankAccountModal] = useState(false);
    const toggleBankAccountModal = () => setShowBankAccountModal(!showBankAccountModal);
    const [idValidationToken, setIdValidationToken] = useState<string | null>(null);
    const [validationLoading, setValidationLoading] = useState<boolean>(false);

    const toggle = () => setDeleteConfirm(!deleteConfirm);

    const success = useAppSelector((state) => state.login.success);
    const error = useAppSelector((state) => state.login.error);


    const passwordResolver = useYupValidationResolver(UpdatePasswordSchema);
    const { register: _registerPassword, handleSubmit: handleSubmitPassword, formState: { errors : errorPassword } } = useForm<UpdatePasswordValues>({resolver: passwordResolver})

    const onSubmitPassword: SubmitHandler<UpdatePasswordValues> = (data) => {
       dispatch(updatePassword(data.password));

    }
    useEffect(() => {
      if (success || error){
        setTimeout(() => {
          dispatch(cleanMessage());
        }, 3000)
      }
    }, [success, error]);



    return <>
          { idValidationToken && <IDValidation token={idValidationToken} onDismiss={ () => { setIdValidationToken(null) }} onDone={ ( ) => { setIdValidationToken(null) }}/> }
          { user && <>
            <Modal toggle={toggleBankAccountModal} centered={true} isOpen={showBankAccountModal}>
              <ModalHeader style={{backgroundColor: '#131015', border: 'none'}}><Trans i18nKey={"form.param"}>Paramètres de l'entreprise</Trans></ModalHeader>
              <ModalBody style={{backgroundColor: '#131015', border: 'none'}}>
                <BankWizard details={user.profile?.companyDetails} onClose={() => {toggleBankAccountModal()}}/>
              </ModalBody>

          </Modal>
            <DeleteAccountModal show={deleteConfirm} toggle={toggle} />
          { success && <Alert color="success">
            <Trans i18nKey={"form.settings.success"}>Paramètres modifiés avec succès</Trans>
            </Alert> }
            { error && <Alert color="danger">
            <Trans i18nKey={"form.settings.error"}>Erreur lors de la modification des paramètres :</Trans> {error}
            </Alert> }          
          <Form method="post" onSubmit={handleSubmit(onSubmit)} style={{marginBottom: 20}}>
            <h1 style={{fontSize: 22, marginTop: 20}}><Trans i18nKey={"form.settings.identity"}>Identité</Trans></h1>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="formFirstName">
                    <Trans i18nKey="form.firstname">
                                Prénom
                    </Trans>
                  </Label>
                  <FormInput
                    id="formFirstName"
                    placeholder={t('form.firstname')}
                    register={_register}
                    name="firstName" 
                    invalid={errors.firstName}/>                  
      
                    <FormFeedback>
                      { errors.firstName?.message }
                    </FormFeedback>                               
                </FormGroup>                             
              </Col>
              <Col md={6}>
                <FormGroup>
                    <Label for="formLastName">
                    <Trans i18nKey="form.lastname">
                                Nom
                    </Trans>
                    </Label>
                    <FormInput
                        id="formLastName"
                        placeholder={t('form.lastname')}
                        register={_register}
                        name="lastName"
                        invalid={errors.lastName}/>                  
                    </FormGroup>     
                    <FormFeedback>
                      { errors.lastName?.message }
                    </FormFeedback>
              </Col>
            </Row>
            <h1 style={{fontSize: 22, marginTop: 10}}>
              <Trans i18nKey="creator.settings.contact">
                Contact
              </Trans>
            </h1>
            <Row>
              <Col md={6} >
                <FormGroup>
                  <Label for="formEmail">
                    <Trans i18nKey="form.email">
                      Email
                    </Trans>
                  </Label>
                  <FormInput
                    id="FormEmail"
                    placeholder="email"
                    register={_register}
                    name="email" 
                    invalid={errors.email}/>

                    <FormFeedback>
                      { errors.email?.message }
                    </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="formPhoneNumber">
                    <Trans i18nKey="form.phonenumber">
                        Téléphone
                      </Trans>
                  </Label>
                  <FormInput
                    id="formPhoneNumber"
                    placeholder={t('form.phonenumber')}
                    register={_register}
                    name="phone" 
                    invalid={errors.phone}/>

                    <FormFeedback>
                      { errors.phone?.message }
                    </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
               <Button type="submit" color="primary">
                <Trans i18nKey={"form.save"}>Sauvegarder</Trans>
               </Button>
               </Col>
            </Row>
            </Form>

            <h1 style={{fontSize: 22, marginTop: 46}}>
              <Trans i18nKey="creator.settings.identity_validation">
                 Validation de mon identité
              </Trans>
            </h1>

            <Row>
                <Col md={12}>
                  
                  <Button color="primary" onClick={() => {
                    setValidationLoading(true);
                    api.post('/creator/identity-validation').then((response) => {
                      console.log(response.data.token);
                      setIdValidationToken(response.data.token);
                      setValidationLoading(false);
                    }).catch((error) => { 
                      setValidationLoading(false);
                    });
                  }}>{!validationLoading  &&  <Trans i18nKey={"creator.settings.validation"}>Valider mon identité</Trans> }
                  {validationLoading && <Spinner />}
                  </Button>
                </Col>
            </Row>

            <h1 style={{fontSize: 22, marginTop: 46}}>
              <Trans i18nKey="creator.settings.bank_account">
                 Compte bancaire
              </Trans>
            </h1>
            <Row>
                <Col md={12}>
                
                { user?.profile?.companyDetails &&  <>
                  <p>{user.profile.companyDetails.companyName} {user.profile.companyDetails.country}</p>
                  <p>{user.profile.companyDetails.accountNumber} {user.profile.companyDetails.swift}</p>
            
                  <Button type="submit" color="primary" onClick={ () => {
                    toggleBankAccountModal();
                  }}>
                    <Trans i18nKey={"creator.settings.update_bank_account"}>Modifier mon compte bancaire</Trans>
                  </Button>
                </>}
                { !user?.profile?.companyDetails   &&                 
                  <Button type="submit" color="primary" onClick={ () => {
                    toggleBankAccountModal();
                  }}>
                    <Trans i18nKey={"creator.settings.add_bank_account"}>Ajouter un compte bancaire</Trans>
                  </Button>
                }

                </Col>
            </Row>
            

          <Form method="post" onSubmit={handleSubmitPassword(onSubmitPassword)} style={{marginTop: 46}}>
            <h1 style={{fontSize: 22}}><Trans i18nKey={"creator.settings.account_management"}>Gestion de compte</Trans></h1>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="formLastName">
                    <Trans i18nKey={"form.password"}>Mot de passe</Trans>
                  </Label>
                  <FormInput
                    id="formPassword"
                    placeholder="Password"
                    register={_registerPassword}
                    type="password"
                    name="password" 
                    invalid={errorPassword.password}/>
                  <FormFeedback>
                      { errorPassword.password?.message }
                  </FormFeedback> 
                </FormGroup>
               
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="formConfirmPassword">
                    <Trans i18nKey={"form.password_confirm"}>Confirmer Mot de passe</Trans>

                  </Label>
                  <FormInput
                    id="formConfirmPassword"
                    placeholder="Password"
                    register={_registerPassword}
                    type="password"
                    name="confirmPassword" 
                    invalid={errorPassword.confirmPassword}/>
                    
                <FormFeedback>
                    { errorPassword.confirmPassword?.message }
                </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Button type="submit" color="primary">
              <Trans i18nKey={"form.save"}>Sauvegarder</Trans>
            </Button>
            </Form>
            <Row style={{marginTop: 20}}>
              <Col md={12} style={{textAlign: 'center'}}>
                <a style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={ () => {
                  toggle();
                }}><Trans i18nKey={"form.delete_account"}>Supprimer mon compte</Trans></a>
              </Col>
            </Row>
       </>}
    </>
} 