import { Navigate, useParams } from "react-router-dom";

export interface RedirectProps {
    to: string;
}

export default function Redirect(props: RedirectProps){
    const { id } = useParams();
    
    return <Navigate to={props.to+`/${id}`} replace={true} />
}