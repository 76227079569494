import pino from "pino";



const logger = pino({
    enabled: process.env.NODE_ENV === 'development',
    browser: {
      serialize: true,
      asObject: true,
    },
  });
  
  export default logger;