import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useContext, useState } from "react";
import socketContext from "../context/socket-context";

export interface ReportModalProps{
    visible : boolean;
    onReportDone: Function;
}

export default function ReportModal(props: ReportModalProps){
    const socket = useContext(socketContext);

    const [reportContent, setReportContent] = useState<string>("");
    const [reportError, setReportError] = useState<string>("");

    

    return <Modal isOpen={props.visible} centered={true}>
    <ModalHeader>
        Signaler un problème
    </ModalHeader>
    <ModalBody>
        {reportError && <p style={{color: "red"}}>{reportError}</p> }
        <FormGroup>
                <Label for="explain">Pouvez vous décrire le problème que vous rencontrez ?</Label>
                <textarea id="explain" style={{width: "100%", height: 100}} onChange={ (e) => (
                    setReportContent(e.target.value)
                )} value={reportContent} /> 
        </FormGroup>
    </ModalBody>
    <ModalFooter>
        <Button color="primary" onClick={ () => {
            socket.emit('user-report', {content: reportContent});
            props.onReportDone();

        }}>Envoyer</Button>
        <Button color="primary" onClick={ () => {
            props.onReportDone();
        }}>Annuler</Button>
    </ModalFooter>
</Modal> 
}