import { Participant } from "amazon-ivs-web-broadcast/dist/src/stage/core-api/stage-connection";
import { getMediaForDevices, CAMERA, MIC, DeviceType } from "./mediaDevices";
import IVSBroadcastClient, { ConnectionState, LocalStageStream, Stage, StageEvents, SubscribeType } from 'amazon-ivs-web-broadcast';

// Function creates a local stage stream based on the specified device ID and type (CAMERA or MIC).
export const createLocalStageStream = async (deviceId: string, deviceType: DeviceType) => {

  // Warn and return if the device ID is null
  /*if (!deviceId) {
    console.warn("Attempted to set local media with a null device ID");
    return;
  }*/
  // Get media stream for the specified device
  const newDevice = await getMediaForDevices(deviceId, deviceType);

  // Create a LocalStageStream based on the device type
  const stageStream =
    deviceType === DeviceType.CAMERA
      ? new LocalStageStream(newDevice.getVideoTracks()[0])
      : new LocalStageStream(newDevice.getAudioTracks()[0]);

  return stageStream;
};

/**
 * Sets up the Strategy for 3 major actions a user performs: which streams to publish, should streams be published, subcribing to streams
 * @param {*} cameraStageStream The current user's camera MediaStream
 * @param {*} micStageStream The current user's microphone MediaStream
 * @returns strategy object
 */

// Function creates a strategy object for IVS stage, considering initialization status
const setupStrategy = (
  isInitializeComplete : any// Parameter representing the initialization completion status
) => {
  // Check if the initialization is complete; if not, return nothing
  if (!isInitializeComplete) {
    return;
  }

  // More information can be found here: https://aws.github.io/amazon-ivs-web-broadcast/docs/v1.3.1/sdk-reference/enums/SubscribeType?_highlight=subscribetype

  const strategy = {
    audioTrack: undefined,
    videoTrack: undefined,
    isOwner: false,
    isInvited: false,

    
    updateMe(isOwner : any, isInvited : any) {
      this.isOwner = isOwner;
      this.isInvited = isInvited;
    },

    // Method to update audio and video tracks
    updateTracks(newAudioTrack : any, newVideoTrack: any) {

      this.audioTrack = newAudioTrack;
      this.videoTrack = newVideoTrack;
    },

    // Method to define streams to publish
    stageStreamsToPublish() {
        return [this.audioTrack, this.videoTrack];
    },

    // Method to determine participant publishing
    shouldPublishParticipant(participant: Participant) {
      return this.isOwner || this.isInvited;
    },

    // Method to determine type of subscription for participants
    shouldSubscribeToParticipant(participant: any) {
      return SubscribeType.AUDIO_VIDEO;
    },
  };

  return strategy; // Return the strategy object
};

/**
 * Click handler for Join Stage Button
 */

export const joinStage = async (
  isInitializeComplete : any, // Indicates if the initialization is complete
  me: any, // Represents the current user
  participantToken: string, // Token of the participant
  selectedAudioDeviceId : any, // Represents the selected audio device
  selectedVideoDeviceId : any, // Represents the selected video device
  setIsConnected: Function, // Setter for the connection status
  setIsMicMuted: Function, // Setter for the microphone mute state
  setParticipants: Function, // Setter for the list of participants
  stageRef: any, // Setter for the stage
  strategyRef: any
) => {


  if (!isInitializeComplete) return; // If the initialization is not complete, stop execution and return
  

  const cameraStageStream = await createLocalStageStream(
    selectedVideoDeviceId,
    DeviceType.CAMERA
  );

  const micStageStream = await createLocalStageStream(
    selectedAudioDeviceId,
    DeviceType.MIC
  );

  // Set up the strategy for the stage
  const strategy = setupStrategy(isInitializeComplete);
  strategy?.updateMe(me.role == 2, me.isInvited);
  strategy?.updateTracks(micStageStream, cameraStageStream);

  strategyRef.current = strategy;

  // Create a new stage instance
  let stage : Stage | null = new Stage(participantToken, strategyRef.current);

  // Event listener for stage connection state changes
  stage.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, (state: any) => {
    // Update the connection status
    setIsConnected(state === ConnectionState.CONNECTED);

    // Mute the microphone stage stream and update the state for the mic button
    //micStageStream?.setMuted(true);
    setIsMicMuted(true);
  });

  // Event listener for when participant streams are added
  stage.on(
    StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED,
    (participant : any, streams : any) => {
      console.log("Participant Media Added: ", participant, streams);

      setParticipants((prevParticipants: any) => {
        const participantExists = prevParticipants.some(
        (participantObj: any) => participantObj.participant.id === participant.id);

        if (!participantExists) {
          return [...prevParticipants, { participant, streams }];
        } else {
          return prevParticipants;
        }
      });
    }
  );

  // Event listener for when a participant leaves
  stage.on(StageEvents.STAGE_PARTICIPANT_LEFT, (participant: any) => {
    console.log("Participant Left: ", participant);

    // Update the list of participants by removing the participant who left
    setParticipants((prevParticipants: any[]) => {
      const filteredParticipants = prevParticipants.filter(
        ({ participant: currentParticipant }) => {
          return currentParticipant.id !== participant.id;
        }
      );
      return [...filteredParticipants];
    });
  });

  try {
    await stage.join(); // Attempt to join the stage
  } catch (err) {
    stage = null;
  }

  stageRef.current = stage;
};

/**
 * Click handler for the Leave Stage button
 */
export const leaveStage = async (stage: any, setIsConnected: Function) => {
  await stage.leave();
  setIsConnected(false);
};
