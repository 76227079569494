import { Page } from "../../../theme/templates/page";

export default function Declaration(){
    return <Page>
        <div style={{width: "80%", margin: "auto"}}>
        <h1 style={{marginTop: 100}}>ANTI MODERN SLAVERY DECLARATION</h1>

        <p>Blush stands against all forms of modern slavery and human trafficking, sex trafficking or abuse. Modern slavery is a crime and a violation of fundamental human
        rights. It takes various forms such as slavery, servitude, forced and compulsory labour and human trafficking, all of which have in common the deprivation of a person’s  liberty by another in order to exploit them for personal or commercial gain.
        We are committed to acting with integrity and mitigating the risk of modern slavery and human trafficking in our business dealings and relationships with  our creators and users. We are also committed to treating all employees, partners
        and vendors with respect and dignity, ensuring safe working conditions,
        and conducting environmentally responsible and ethical operations.agrement</p>
        </div>
    </Page>
}