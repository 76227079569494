import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays } from "date-fns";
import { DateRangePicker } from 'rsuite';

const predefinedRanges : any= [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
  ];


function afterToday() {
    return (date: Date) => date > new Date();
}
interface WeerliveRangePickerProps {
    onChange: (range: Date[] | null) => void;
}
export default function WeerliveRangePicker(props: WeerliveRangePickerProps) {
    return <DateRangePicker ranges={predefinedRanges} showOneCalendar shouldDisableDate={afterToday()}  onChange={props.onChange} />
}
  