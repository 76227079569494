import { useState } from "react";
import { Button, Form } from "reactstrap";
import send from "../../../assets/studio/send.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { set } from "lodash";
import { t } from "i18next";


export interface ChatFormWidgetProps{
    onMessage(message: string): void;
}

const CHAT_MAX_LENGTH = 300;

export default function ChatFormWidget(props: ChatFormWidgetProps){
    const [chatMessage, setChatMessage] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);

    return <Form onSubmit={ (event) => {
            event.preventDefault();
            if (chatMessage === "" || chatMessage.length >= CHAT_MAX_LENGTH)  return;
            props.onMessage(chatMessage);
            setChatMessage("");
            setIsError(false);
        }}
        style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
            
        }}>
            <input type="text" value={chatMessage} style={{flex: 1, backgroundColor: "#424242", borderRadius: 10, color: "white", padding: 10, fontSize: '16px',  border: isError ? "2px solid red" : "none",  marginRight: 10}} placeholder={t('studio.right_bar.message')} onChange={ (event) => { 
                if (chatMessage.length > CHAT_MAX_LENGTH) {
                    setIsError(true);
                }else {
                    setIsError(false);
                }
                setChatMessage(event.target.value)} 
            }></input>
            <Button type="submit" style={{backgroundColor: "#7580FC"}}><FontAwesomeIcon icon={faPaperPlane} /></Button>
        </Form>   
}
        